import { STORE_REFRESH_TOKEN_NAME } from 'shared/constants'

import { api, apiV2, tokenApi, tokenApiV2 } from '../base'

import type {
	IAuthFields,
	IAuthFieldsPhone,
	IAuthResponse,
	IHistoryClass,
	IRecoveryPasswordFields,
	IRegisterFields,
	ISettingFields,
	ITelegramUser,
	IUser,
	IUserData,
	IUserRole,
} from './types'

export const updateToken = () => {
	const refresh = localStorage.getItem(STORE_REFRESH_TOKEN_NAME)

	return api.post<IAuthResponse>(`token/refresh/`, { refresh })
}

export const login = (data: IAuthFields) => {
	return api.post<IAuthResponse & { has_account_phone: boolean }>('token/', data)
}

export const telegramAuth = (user: ITelegramUser) => {
	return apiV2.post<IAuthResponse>('telegram_auth/', user)
}
export const phoneAuth = (data: IAuthFieldsPhone) => {
	return apiV2.post<IAuthResponse>('sign_in_phone/', data)
}
export const signUp = (data: IRegisterFields) => {
	return apiV2.post<{ phone: string }>('sign_up/', data)
}
export const register = (data: { phone: string; code: number }) => {
	return apiV2.post<IAuthResponse>('activate_phone/', data)
}
export const requestCodeForSignUp = (data: { phone: string }) => {
	return apiV2.post('check_phone/', data)
}
export const requestCodeForUpdatePhoneNumber = (data: { phone: string }) => {
	return tokenApiV2.post('phone_number_update/', data)
}
export const updatePhoneNumber = (data: { phone: string; code: number }) => {
	return tokenApiV2.post<IAuthResponse>('phone_number_update_activate/', data)
}

export const resetPassword = (data: { phone: string }) => {
	return apiV2.post('reset_password_phone/', data)
}
export const setNewPasswordAfterReset = (data: IRecoveryPasswordFields) => {
	return apiV2.post<IAuthResponse>('set_password_after_reset_phone/', data)
}
export const getUserInfo = () => {
	return tokenApi.get<IUser>('get_my_id/')
}

export const getUserProfile = () => {
	return tokenApi.get<IUserData>('profile/')
}
export const updateUserProfile = (body: ISettingFields) => {
	return tokenApi.patch<Partial<IUserData>>('profile/', body)
}

export const updatePassword = (data: { password: string; password_confirmation: string }) => {
	return tokenApi.patch('change-password/', data)
}

export const getRoles = () => {
	return api.get<IUserRole[]>('role/')
}

export const getHistory = () => {
	return tokenApi.get<IHistoryClass[]>('history/')
}
