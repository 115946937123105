import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { FC, TouchEvent, useEffect, useRef, useState } from 'react'

import { getClassById } from 'shared/api/class'
import { CheckIcon, ClockIcon, ShareIcon } from 'shared/icons'
import { getClassTime } from 'shared/lib/getClassTime'
import { useResize } from 'shared/lib/useResize'
import { IconTitle } from 'shared/ui/iconTitle'
import { LevelInfo } from 'shared/ui/level'
import { LoaderWorkoutDetail } from 'shared/ui/loaders'

import { DetailTabs } from './detailTabs/ui/detailTabs'

import './workoutDetailModal.scss'

interface IWorkoutDetailModal {
	readonly renderButton: () => React.ReactNode
	readonly classId: number
	readonly date: string
	readonly handleCloseDetail: () => void
}

export const WorkoutDetailModal: FC<IWorkoutDetailModal> = ({
	renderButton,
	classId,
	handleCloseDetail,
	date,
}) => {
	/** consts */

	const dayOfTheWeek = dayjs(date).isoWeekday()
	const isOnLeftSide = dayOfTheWeek > 3
	const hasTouch =
		(typeof window !== 'undefined' && 'ontouchstart' in window) || navigator.maxTouchPoints > 0
	/** Hooks */

	const { data: workout, isLoading } = useQuery({
		queryKey: ['workout', classId],
		queryFn: () => getClassById(classId),
		staleTime: 1440 * 30,
	})

	const [isCopiedUrl, setIsCopiedUrl] = useState(false)
	const [touchPosition, setTouchPosition] = useState<number | null>(null)
	const [fastSwipe, setFastSwipe] = useState(false)
	const { LG_SCREEN } = useResize()
	/** refs */
	const overlayWrapperRef = useRef<HTMLDivElement | null>(null)
	const ref = useRef<HTMLDivElement>(null)

	/** Effects */
	useEffect(() => {
		document.body.style.overflow = 'hidden'
		return () => {
			document.body.style.overflow = ''
		}
	}, [])

	useEffect(() => {
		if (overlayWrapperRef && overlayWrapperRef.current) {
			overlayWrapperRef.current.scroll({ top: 100, behavior: 'smooth' })
		}
	}, [overlayWrapperRef])

	/** handlers */
	const handleTouchStart = (e: TouchEvent<HTMLDivElement>) => {
		if (!hasTouch || !LG_SCREEN) return
		const touchDown = e.touches[0].clientY
		setFastSwipe(true)
		setTimeout(() => {
			setFastSwipe(false)
		}, 300)
		if (window.innerHeight - touchDown > 75) return
		setTouchPosition(touchDown)
	}

	const handleTouchMove = (e: TouchEvent<HTMLDivElement>) => {
		if (!hasTouch || touchPosition === null || !LG_SCREEN) return
		if (window.innerHeight - touchPosition > 75) return
		const currentPosition = e.touches[0].clientY
		const diff = touchPosition - currentPosition
		if (ref && ref.current) {
			if (diff >= 0) {
				ref.current.style.transform = `translate3d(-50%, -${diff}px, 0)`
				ref.current.style.transitionDuration = '0s'
			}
		}
	}

	const handleTouchEnd = (e: TouchEvent) => {
		if (!hasTouch || touchPosition === null || !LG_SCREEN) return
		const touchEnd = e.changedTouches[0].clientY
		if (ref && ref.current) {
			ref.current.style.transform = ''
			ref.current.style.transitionDuration = '0.5s'
		}
		const diff = touchPosition - touchEnd
		if (fastSwipe) {
			if (diff > 20) {
				handleCloseDetail()
				return
			}
		}
		if (touchPosition - touchEnd > 200) {
			handleCloseDetail()
		}
		setTouchPosition(null)
	}

	return (
		<div
			id='workout-detail-modal'
			ref={ref}
			onTouchStart={handleTouchStart}
			onTouchMove={handleTouchMove}
			onTouchEnd={handleTouchEnd}
			className={clsx('workout-detail-modal', workout && 'loaded')}
			data-side={isOnLeftSide ? 'left' : 'right'}
		>
			{isLoading && <LoaderWorkoutDetail />}
			{workout && (
				<div className='workout-detail-modal__wrapper'>
					<div className='workout-detail-modal__header'>
						<div>
							<h3 className='workout-detail-modal__header__title'>
								<span className='capitalize'>{dayjs(workout.date).format('dddd, ')}</span>
								<span>{dayjs(workout.date).format('D MMMM ')}</span>
							</h3>
							<IconTitle
								Icon={ClockIcon}
								title={getClassTime(date, workout.duration)}
								className='workout-detail-modal__header__time'
								gap={12}
							/>
						</div>
						<LevelInfo
							className='workout-detail-modal__header__level'
							levelTitle={`${workout.level.title} уровень`}
							levelColor={workout.level.color}
							is_changed_level={workout.is_changed_level}
						/>
					</div>
					<DetailTabs workout={workout} />
					<div className='workout-detail-modal__footer'>
						<div className='workout-detail-modal__footer__wrapper'>
							{renderButton()}
							<button
								onClick={() => {
									const url = window.location.href
									navigator.clipboard.writeText(url)
									setIsCopiedUrl(true)
								}}
								className={clsx('workout-detail-modal__footer__share', isCopiedUrl && 'copied')}
							>
								{isCopiedUrl ? <CheckIcon /> : <ShareIcon />}
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
