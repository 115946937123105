import { queryOptions } from '@tanstack/react-query'

import { getGroupProducts } from 'shared/api/product'

export const getAllProductsOptions = (userId: number | 'guest') =>
	queryOptions({
		queryKey: ['all-products', userId],
		queryFn: getGroupProducts,
		staleTime: 60000 * 30,
	})
