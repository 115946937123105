import { useMutation } from '@tanstack/react-query'

import { getUserProfileData } from 'shared/api/account'
import { useSessionStore } from 'shared/model'

import { clearSession } from '../auth/sessions'
import { toastError } from '../toastError'

export function useGetSession() {
	const session = useSessionStore((state) => state.session)
	const setSession = useSessionStore((state) => state.setSession)
	return useMutation({
		mutationFn: () => getUserProfileData(),
		mutationKey: ['currentSession', session?.id],
		onSuccess: ({ balance, id, near_klass }) => {
			setSession({ balance, id, near_klass })
		},
		onError: () => {
			clearSession()
			toastError({ message: 'Не удалось загрузить сессию' })
		},
	})
}
