// Context
import clsx from 'clsx'
import DropDownIcon from 'components/IconComponents/DropDownIcon'
import dayjs from 'dayjs'
import { FC, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { ToggleTheme } from 'features/toggleTheme'

import { HEADER_LINKS } from 'shared/constants'
import {
	BellIcon,
	DottedClockIcon,
	LogoutIcon,
	PersonIcon,
	TelegramIcon,
	WarningIcon,
} from 'shared/icons'
import { clearSession, getSession } from 'shared/lib/auth/sessions'
import { useResize } from 'shared/lib/useResize/useResize'
import { pluralWorkouts } from 'shared/lib/utils'
import { Theme, useSessionStore, useThemeContext } from 'shared/model'
import { CountTip } from 'shared/ui/countTip'
import { HamburgerButton } from 'shared/ui/hamburgerButton'
import { IconTitle } from 'shared/ui/iconTitle'
import { Logo } from 'shared/ui/logo'

import './Header.scss'

interface IHeader {
	readonly openNotifications: () => void
	readonly countNotifications: number
}
export const Header: FC<IHeader> = ({ openNotifications, countNotifications }) => {
	const session = useSessionStore((state) => state.session)
	const setSession = useSessionStore((state) => state.setSession)
	const setIsPopupAccountOpen = useSessionStore((state) => state.setIsPopupAccountOpen)

	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const navRef = useRef<HTMLElement>(null)
	const triggerRef = useRef<HTMLButtonElement>(null)
	const renderNearWorkout = (date: string | null) => {
		if (!date) return 'Ближайших нет'
		const dateWorkout = dayjs(date)
		return dateWorkout.format('dd, DD MMM в HH:mm')
	}
	const hasAuth = getSession()
	const navigate = useNavigate()
	const handleLogin = () => {
		if (hasAuth) {
			navigate('/profile/about')
			return
		}
		clearSession()
		setIsPopupAccountOpen(true)
	}
	const handleLogout = () => {
		clearSession()
		setSession(null)
	}
	const { pathname } = useLocation()
	useEffect(() => {
		setIsMenuOpen(false)
	}, [pathname])
	const { MD_SCREEN, XXL_SCREEN, SM_SCREEN, LG_SCREEN } = useResize()
	const { theme } = useThemeContext(({ theme }) => ({
		theme,
	}))
	const isDarkMode = theme === Theme.DARK
	useEffect(() => {
		document.body.style.overflow = isMenuOpen ? 'hidden' : ''
	}, [isMenuOpen])

	return (
		<>
			<header
				className='header'
				id='header'
			>
				<div className='header__content'>
					{/*** Logo ***/}
					<Link
						to='/'
						className='header__logo'
					>
						<Logo />
					</Link>
					{/*** Menu ***/}
					{!LG_SCREEN && (
						<nav className='header__nav'>
							<ul className='header__list'>
								<li
									className={clsx(
										'header__item header__item__dropdown',
										(pathname === '/training/' || pathname === '/gym/') && 'active'
									)}
								>
									<span className='header__item__dropdown__title'>
										Тренировки
										<DropDownIcon className='header__item__dropdown__icon' />
									</span>
									<ul className='header__item__dropdown__list'>
										<li className='header__item__dropdown__list__item'>
											<Link to={'/training/'}>Виды тренировок</Link>
										</li>
										<li className='header__item__dropdown__list__item'>
											<Link to={'/gym/'}>Залы</Link>
										</li>
									</ul>
								</li>
								<li className={clsx('header__item', pathname === '/schedule/' && 'active')}>
									<Link to={'/schedule/'}>Расписание</Link>
								</li>
								<li className={clsx('header__item', pathname === '/payment/' && 'active')}>
									<Link to={'/payment/'}>Цены</Link>
								</li>
							</ul>
						</nav>
					)}

					<div className='header__actions'>
						{!SM_SCREEN && <ToggleTheme className='header__actions__icon' />}
						{session && !XXL_SCREEN && (
							<>
								<div
									data-text='Ваша ближайшая тренировка'
									className='header__actions__userInfo near-class'
								>
									{renderNearWorkout(session.near_klass && session.near_klass.date)}
								</div>
								<div
									data-counter='true'
									data-text={`${
										session.balance.length > 0
											? `Осталось в абонементе${
													session.balance[0].date_end
														? ' до ' + dayjs(session.balance[0].date_end).format('D MMM')
														: ''
											  }`
											: 'У вас нет активных абонементов'
									}`}
									className='header__actions__userInfo'
								>
									{session.balance?.length > 0 ? session.balance[0].balance : 0}
								</div>
							</>
						)}
						{!MD_SCREEN && (
							<Link
								className='header__actions__icon'
								to='https://t.me/volleybox_official'
								target='_blank'
							>
								<TelegramIcon />
							</Link>
						)}
						<button
							onClick={openNotifications}
							className='header__actions__icon bell'
						>
							<BellIcon />
							<CountTip count={countNotifications} />
						</button>
						{!hasAuth ? (
							<button
								className='header__actions__icon'
								onClick={() => handleLogin()}
							>
								<PersonIcon />
							</button>
						) : (
							<Link
								to='/profile/about'
								className='header__actions__icon'
							>
								<PersonIcon />
							</Link>
						)}
						{/*** Logout ***/}
						{hasAuth && (
							<button
								className='header__actions__icon logout'
								onClick={handleLogout}
							>
								<LogoutIcon />
							</button>
						)}

						{/*** Burger ***/}
						{LG_SCREEN && (
							<HamburgerButton
								className='header__actions__icon burger'
								ref={triggerRef}
								onClick={() => setIsMenuOpen(!isMenuOpen)}
								active={isMenuOpen}
							/>
						)}
					</div>
				</div>
			</header>
			{LG_SCREEN && (
				<>
					<nav
						ref={navRef}
						className={clsx('header__nav-mob', isMenuOpen && 'header__nav-mob--open')}
					>
						<ul className='header__nav-mob__list'>
							{HEADER_LINKS.map(({ path, title }) => (
								<li
									key={path}
									onClick={() => setIsMenuOpen(false)}
									className='header__nav-mob__item fade-in'
								>
									<Link
										className={clsx('header__nav-mob__link', pathname === path && 'active')}
										to={path}
									>
										{title}
									</Link>
								</li>
							))}
						</ul>
						{session && (
							<div className='header__nav-mob__eventsInfo'>
								<div className='header__nav-mob__item'>
									<IconTitle
										Icon={DottedClockIcon}
										title={renderNearWorkout(session.near_klass && session.near_klass.date)}
									/>
									<p className='header__nav-mob__eventsInfo__tip'>Ваша ближайшая тренировка</p>
								</div>
								<div className='header__nav-mob__item'>
									<IconTitle
										Icon={WarningIcon}
										title={
											session.balance.length > 0
												? pluralWorkouts(session.balance[0].balance)
												: 'Нет активных абонементов'
										}
									/>
									<p className='header__nav-mob__eventsInfo__tip'>
										{session.balance.length > 0
											? `Осталось в абонементе${
													session.balance[0].balance
														? ' до ' + dayjs(session.balance[0].date_end).format('D MMM')
														: ''
											  }`
											: 'Пора приобрести новый абонемент'}
									</p>
								</div>
							</div>
						)}
						{SM_SCREEN && (
							<ToggleTheme className='header__nav-mob__item header__nav-mob__theme'>
								<span>{isDarkMode ? 'Светлая тема' : 'Темная тема'}</span>
							</ToggleTheme>
						)}
					</nav>
					<div
						onClick={() => setIsMenuOpen(false)}
						className={clsx('header__nav-mob__overlay', isMenuOpen && 'active')}
					/>
				</>
			)}
		</>
	)
}
