import { FC } from 'react'
import { Link, useRouteError } from 'react-router-dom'

import { Button } from '../button'

import './fallback.scss'

interface IFallback {}

export const Fallback: FC<IFallback> = () => {
	window.scrollTo(0, 0)
	const error = useRouteError() as { status: number; message: string }
	return (
		<div className='fallback'>
			<section className='fallback__content'>
				{error?.status === 404 ? (
					<>
						<h1>Упс...</h1>
						<h2>Такой страницы не существует </h2>
					</>
				) : (
					<>
						<h1>{error.status}</h1>
						<h2>{error?.message ?? 'Неизвестная ошибка'} </h2>
						<p className='error-page__text'>Произошла ошибка, вернитесь на главную страницу</p>
					</>
				)}
				<Link to='/'>
					<Button>На главную</Button>
				</Link>
			</section>
		</div>
	)
}
