import clsx from 'clsx'
import { FC } from 'react'

import { ChangeIcon } from 'shared/icons'

import './iconTitle.scss'

interface IIconTitle {
	readonly className?: string
	readonly Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
	readonly title: string
	readonly gap?: 5 | 12
	readonly color?: 'white' | 'gray'
	readonly isChanged?: boolean
}

export const IconTitle: FC<IIconTitle> = ({
	className,
	Icon,
	title,
	isChanged,
	gap = 5,
	color = 'white',
}) => {
	return (
		<div
			className={clsx(className, 'icon-title')}
			data-color={isChanged ? 'red' : color}
			data-gap={gap}
		>
			{isChanged ? <ChangeIcon /> : <Icon />}
			<span className='ellipsis'>{title}</span>
		</div>
	)
}
