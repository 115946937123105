import clsx from 'clsx'
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import { FC, useEffect, useRef, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { WorkoutDetailModal } from 'widgets/workoutDetailModal'

import {
	JoinClassInfo,
	useJoinClassMutate,
	useJoinClassStore,
	useLeaveClassMutate,
	usePreJoinClassMutate,
} from 'entities/joinClass'

import { ClockIcon, EyeIcon, MetroLocationIcon, TrainerIcon } from 'shared/icons'
import { clearSession } from 'shared/lib/auth/sessions'
import { getClassTime } from 'shared/lib/getClassTime'
import { useCustomTimeout } from 'shared/lib/useCustomTimeout/useCustomTimeout'
import { useResize } from 'shared/lib/useResize'
import { useSessionStore } from 'shared/model'
import { IClass } from 'shared/types'
import { IRoles } from 'shared/types/classTypes'
import { Button } from 'shared/ui/button'
import { IconTitle } from 'shared/ui/iconTitle'
import { LevelInfo } from 'shared/ui/level'
import { Modal } from 'shared/ui/modal'
import { ProgressBar } from 'shared/ui/progressBar'

import './ScheduleWorkout.scss'

dayjs.locale('ru')

interface IScheduleWorkout {
	readonly classData: IClass
}
export const ScheduleWorkout: FC<IScheduleWorkout> = ({ classData }) => {
	/** consts */
	const {
		id: classId,
		date,
		free_remove_to_klass,
		limit,
		players,
		roles_available,
		is_role_mode,
		level,
		gym,
		duration,
		trainer,
		is_changed_date,
		is_changed_gym,
		is_changed_level,
		is_changed_trainer,
		is_changed_type,
		type,
	} = classData
	const interval = getClassTime(date, duration)
	const freeRemoveDeadline = dayjs(date).subtract(free_remove_to_klass, 'hour')
	const isEndedClass = dayjs(date).diff(dayjs()) < 0

	const isFreeRemoveAvailable = dayjs().isBefore(freeRemoveDeadline)
	/** Hooks */
	const { session, setIsPopupAccountOpen } = useSessionStore(
		({ session, setIsPopupAccountOpen }) => ({
			session,
			setIsPopupAccountOpen,
		})
	)
	const isPlayerAlreadyJoined = session ? players.includes(session.id) : false
	const [joined, setJoined] = useState(isPlayerAlreadyJoined) // Состояние записи пользователя
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [isModalCancelConfirmOpen, setIsModalCancelConfirmOpen] = useState(false)
	const [playersCount, setPlayersCount] = useState(players.length)
	const [isConfirmMode, setIsConfirmMode] = useState(true)
	const resetStore = useJoinClassStore((state) => state.reset)
	const [isOpenDetail, setIsOpenDetail] = useState(false)
	const [blockDoubleClick, setBlockDoubleClick] = useState(false)
	const [searchParams, setSearchParams] = useSearchParams()
	const { LG_SCREEN } = useResize()
	const { customSetTimeoutFn } = useCustomTimeout()
	const { state } = useLocation()
	const {
		mutate: mutateJoinClass,
		isPending: isJoinClassLoading,
		isError: isJoinClassError,
	} = useJoinClassMutate(classId, setPlayersCount, setJoined, handleCloseModal)
	const { mutate: mutatePreJoinClass, isPending: isPreJoinClassLoading } = usePreJoinClassMutate(
		classId,
		date,
		handleOpenModal,
		mutateJoinClass
	)

	const { mutate: mutateLeaveClass, isPending: isLeaveClassLoading } = useLeaveClassMutate(
		classId,
		setPlayersCount,
		setJoined,
		setIsConfirmMode
	)

	/** Others */
	const crowded = playersCount >= limit

	/** Effects */
	useEffect(() => {
		const delayed = sessionStorage.getItem('delayed_appointment')

		const isFromSuccess = state?.from === 'payment-success'
		if (delayed && isFromSuccess) {
			const delayed_data = JSON.parse(delayed) as {
				classId: number
				role: keyof IRoles
			}
			if (delayed_data.classId === classId) {
				const schedule = document.querySelector('.schedule')
				schedule?.scrollIntoView({ behavior: 'smooth' })
				mutatePreJoinClass({
					classId,
					is_role_mode,
					delayed_join: true,
					role: delayed_data.role,
				})
				sessionStorage.removeItem('delayed_appointment')
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	/** handlers */
	const handleOpenDetail = () => {
		if (!isOpenDetail) {
			const encryptedDate = window.btoa(dayjs(date).format('YYYY-MM-DD'))
			const encryptedId = window.btoa(classId.toString())
			setSearchParams({ workout: encryptedId, date: encryptedDate, tab: 'squad' })
			setIsOpenDetail(true)
		}
	}
	const handleCloseDetail = () => {
		const details = document.getElementById('workout-detail-modal')
		const overlay = document.getElementById('workout-detail-overlay')
		overlay?.classList.add('workout-detail-overlay__hidden')
		details?.classList.add('detail-hidden')
		setBlockDoubleClick(true)
		customSetTimeoutFn(() => {
			setIsOpenDetail(false)
			setBlockDoubleClick(false)
		}, 800)
		setSearchParams((params) => {
			params.delete('workout')
			params.delete('date')
			return params
		})
	}
	function handleCloseModal() {
		setIsModalOpen(false)
		resetStore()
	}
	function handleOpenModal() {
		setIsModalOpen(true)
	}
	const encryptedWorkout = searchParams.get('workout')
	if (encryptedWorkout) {
		let decryptedWorkout = ''
		try {
			decryptedWorkout = window.atob(encryptedWorkout)
		} catch {
			const newSearchParams = new URLSearchParams(window.location.search)
			newSearchParams.delete('workout')
			newSearchParams.delete('date')
			setSearchParams(newSearchParams)
		}
		if (decryptedWorkout === classId.toString()) {
			if (!isOpenDetail) {
				setTimeout(() => {
					setIsOpenDetail(true)
				}, 200)
			}
		}
	}
	const handleCloseConfirmCancel = () => {
		const modal = document.getElementById('modal-dialog')
		modal?.classList.add('modal-out')
		setTimeout(() => {
			if (!isConfirmMode) {
				setIsConfirmMode(true)
			}
			if (!isConfirmMode) {
				setIsConfirmMode(true)
			}
			setIsModalCancelConfirmOpen(false)
		}, 500)
	}

	/** render */
	const renderJoinButton = () => (
		<Button
			disabled={crowded && !isEndedClass}
			className={'schedule-class__join-class__btn schedule-class__btn'}
			isLoading={
				(isJoinClassLoading && !isJoinClassError) || (isPreJoinClassLoading && !isJoinClassError)
			}
			onClick={(e) => {
				e.stopPropagation()
				if (!session) {
					clearSession()
					setIsPopupAccountOpen(true)
					return
				}
				mutatePreJoinClass({ classId, is_role_mode })
			}}
		>
			{isEndedClass ? 'Завершено' : !crowded ? 'Записаться' : 'Запись закрыта'}
		</Button>
	)

	const renderLeaveButton = (isInSchedule?: boolean) => (
		<Button
			className={'schedule-class__leave-class__btn schedule-class__btn'}
			theme='red'
			onClick={(e) => {
				e.stopPropagation()
				setIsModalCancelConfirmOpen(true)
			}}
		>
			{isInSchedule && !LG_SCREEN ? 'Отменить' : 'Отменить запись'}
		</Button>
	)
	return (
		<>
			<article
				onClick={() => {
					if (isOpenDetail) {
						handleCloseDetail()
					} else {
						if (blockDoubleClick) return
						handleOpenDetail()
					}
				}}
				className={clsx(
					'schedule-class',
					!isEndedClass && 'future-class',
					isOpenDetail && !LG_SCREEN && 'active'
				)}
				data-color-id={dayjs(date).day() % 2 === 0 ? 2 : 2}
			>
				<div className='schedule-class__content'>
					<LevelInfo
						levelTitle={level.title}
						levelColor={level.color}
						is_changed_level={is_changed_level}
						className='schedule-class__level schedule-class__data'
					/>
					<IconTitle
						Icon={MetroLocationIcon}
						isChanged={is_changed_gym}
						title={gym.title}
						color='gray'
						className='schedule-class__data'
					/>
					<IconTitle
						Icon={ClockIcon}
						title={interval}
						isChanged={is_changed_date}
						color='gray'
						className='schedule-class__data'
					/>
					<IconTitle
						Icon={TrainerIcon}
						title={`${trainer.last_name} ${trainer.first_name}`}
						isChanged={is_changed_trainer}
						color='gray'
						className='schedule-class__data'
					/>
					<div className={clsx('schedule-class__progress-wrapper', 'squad-progress')}>
						<ProgressBar
							className='schedule-class__progress'
							current={playersCount}
							max={limit}
							height={24}
						/>
					</div>
					{type === 'Командная' && (
						<span
							className='schedule-class__team-tooltip'
							data-tooltip={type}
						>
							<span className='schedule-class__team-tooltip__text'>
								{LG_SCREEN ? type : type.slice(0, 1)}
							</span>
						</span>
					)}
				</div>
				<div className='schedule-class__footer'>
					<div className='schedule-class__actions'>
						{(!joined || isEndedClass) && renderJoinButton()}
						{session && joined && !isEndedClass && renderLeaveButton(true)}
					</div>
					<div className='schedule-class__free-leave-tip'>
						<p>Бесплатная отмена</p>
						<p>
							{isFreeRemoveAvailable
								? `до ${freeRemoveDeadline.format('HH:mm, D MMMM')}`
								: 'уже недоступна'}
						</p>
					</div>
				</div>
			</article>
			{isModalOpen && (
				<Modal
					className='join-class-modal'
					closeButton
					onClose={handleCloseModal}
					isHighPriority
					title='Запись на тренировку'
				>
					<JoinClassInfo
						handleJoinClass={mutateJoinClass}
						rolesAvailable={roles_available}
						date={date}
						isJoinClassLoading={isJoinClassLoading}
						classId={classId}
					/>
				</Modal>
			)}
			{isModalCancelConfirmOpen && (
				<Modal
					onClose={() => setIsModalCancelConfirmOpen(false)}
					title={isConfirmMode ? 'Отменить запись' : 'Отмена'}
					isHighPriority
				>
					<p className='modal-cancel-appoint__subtitle'>
						{isConfirmMode ? (
							<>
								{!isFreeRemoveAvailable && (
									<>
										<span className='bold red-highlight'>Занятие не вернется в абонемент.</span>
										<br />
									</>
								)}
								<span>Вы уверены, что хотите отменить запись?</span>
							</>
						) : (
							'Ваша запись успешно отменена'
						)}
					</p>
					<div className='modal-cancel-appoint__actions'>
						{isConfirmMode ? (
							<>
								<Button
									isLoading={isLeaveClassLoading}
									onClick={() => mutateLeaveClass(classId)}
								>
									Да
								</Button>
								<Button
									onClick={handleCloseConfirmCancel}
									theme='red'
								>
									Нет
								</Button>
							</>
						) : (
							<Button onClick={handleCloseConfirmCancel}>К расписанию</Button>
						)}
					</div>
				</Modal>
			)}
			{isOpenDetail && (
				<>
					<div
						id='workout-detail-overlay'
						onClick={handleCloseDetail}
						className='workout-detail-overlay'
					/>
					<WorkoutDetailModal
						classId={classId}
						date={date}
						handleCloseDetail={handleCloseDetail}
						renderButton={session && joined && !isEndedClass ? renderLeaveButton : renderJoinButton}
					/>
				</>
			)}
		</>
	)
}
