import { FC } from 'react'

import { getLinkToPay } from 'shared/api/product'
import { IProduct } from 'shared/api/product/types'
import { getDiscountPercentage } from 'shared/lib/getDiscountPercentage'
import { handleErrorStatus, toastError } from 'shared/lib/handleError'
import { useSessionStore } from 'shared/model'
import { Button } from 'shared/ui/button'

import './productCard.scss'

interface IProductCard {
	readonly product: IProduct
}
export const ProductCard: FC<IProductCard> = ({ product }) => {
	const price = Number(product.price)
	const setIsPopupAccountOpen = useSessionStore((state) => state.setIsPopupAccountOpen)
	const old_price = Number(product.old_price)
	const isSingle = product.slug === 'first'
	const isSingleOrTrial = isSingle || product.slug === 'trial'
	const handleRedirectToPay = async (id: number) => {
		try {
			const response = await getLinkToPay(id)
			window.location.href = response.url
		} catch (err) {
			if (handleErrorStatus(err) === 401) {
				setIsPopupAccountOpen(true)
			} else {
				toastError(err)
			}
		}
	}

	const dataArray = [
		{ title: 'Количество тренировок', value: product.count_class },
		{
			title: 'Срок действия',
			value: isSingleOrTrial ? '∞' : `${product.duration} дней`,
		},
		{
			title: 'Заморозка абонемента',
			value: isSingleOrTrial ? '-' : 'До 7 дней',
		},
		{
			title: 'Скидка за одну тренировку',
			value: isSingle ? '-' : `${getDiscountPercentage(product.old_price, product.price)}%`,
		},
		{ title: 'Макс кол-во на тренировке', value: 16 },
	]
	const countPrice = (price: number, workoutsCount: number) => (price / workoutsCount).toFixed(0)
	return (
		<div className='product-card'>
			<div className='product-card__wrapper'>
				<div className='product-card__header'>
					<p className='product-card__header__product-name'>{product.title}</p>
				</div>
				<div className='product-card__prices'>
					<p className='product-card__prices__per-training'>Цена за 1 тренировку</p>
					<div className='product-card__prices__discount'>
						<h3 className='product-card__prices__price'>
							{countPrice(price, product.count_class)} ₽
						</h3>
						{!isSingle && (
							<p className='product-card__prices__discount__price'>
								{countPrice(old_price, product.count_class)} ₽
							</p>
						)}
					</div>
				</div>
				<ul className='product-card__description'>
					{dataArray.map(({ title, value }) => (
						<li
							key={title}
							className='product-card__description__item'
						>
							<span>{title}</span>
							<span className='product-card__description__item__value'>{value}</span>
						</li>
					))}
				</ul>
				<div className='product-card__total'>
					<p className='product-card__total__title'>Итого</p>
					<p className='product-card__total__price'>{price} ₽</p>
				</div>
			</div>
			<Button
				onClick={() => handleRedirectToPay(product.id)}
				bold
				className='product-card__button'
			>
				Купить
			</Button>
		</div>
	)
}
