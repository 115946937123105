import dayjs from 'dayjs'
import cookie from 'js-cookie'
import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'
import { toast } from 'sonner'

import { SendCodeAgain } from 'features/sendCodeAgain'

import { STORE_SENDED_CODE_TIME } from 'shared/constants'
import { Input } from 'shared/input'
import { BackButton } from 'shared/ui/backButton'
import { Button } from 'shared/ui/button'

import styles from './codeForm.module.scss'

interface ICodeForm {
	readonly phone: string
	readonly onSubmit: (code: number) => void
	readonly isLoading?: boolean
}

export const CodeForm: FC<ICodeForm> = ({ phone, onSubmit, isLoading }) => {
	type TCodeFormData = {
		code0: string
		code1: string
		code2: string
		code3: string
	}
	const {
		control,
		handleSubmit,
		setFocus,
		watch,
		setValue,
		getFieldState,
		formState: { isValid, validatingFields },
	} = useForm<TCodeFormData>()
	const inputRefs = useRef<(HTMLInputElement | null)[]>([])
	const watchedValues = watch()

	/** Effects */
	useEffect(() => {
		if (inputRefs.current[0]) {
			inputRefs.current[0]?.focus()
		}
	}, [])
	/** Handlers */
	const isInputDisabled = (index: number) => {
		// Находим индекс первого пустого инпута
		const firstEmptyIndex = Array(4)
			.fill(0)
			.findIndex((_, i) => !watchedValues[`code${i}` as keyof TCodeFormData])

		// Блокируем все инпуты после первого пустого
		return firstEmptyIndex !== -1 && index > firstEmptyIndex
	}
	function onEnterCode(data: TCodeFormData) {
		const code = Object.values(data).join('')
		onSubmit(Number(code))
	}
	const handleInputChange =
		(index: number, onChange: (value: string) => void) => (e: ChangeEvent<HTMLInputElement>) => {
			const value = e.target.value

			if (/^\d$/.test(value)) {
				onChange(value)
				if (index < 3) {
					setTimeout(() => {
						inputRefs.current[index + 1]?.focus()
					}, 0)
				} else {
					handleSubmit(onEnterCode)()
				}
			} else if (value === '') {
				onChange(value)
				if (index > 0) {
					setTimeout(() => {
						inputRefs.current[index - 1]?.focus()
					}, 0)
				}
			}
		}

	const handleKeyDown = (index: number) => (e: KeyboardEvent<HTMLInputElement>) => {
		if (
			e.key === 'Backspace' &&
			e.currentTarget.value === '' &&
			index > 0 &&
			inputRefs.current?.[index - 1]
		) {
			setTimeout(() => {
				inputRefs.current[index - 1]?.focus()
				setValue(`code${index - 1}` as keyof TCodeFormData, '')
			}, 0)
		}
	}

	return (
		<div className={styles.confirm}>
			<span className={styles.title}>
				Отправили звонок на номер {phone}, введите последние 4 цифры номера, с которого вы получите
				звонок
			</span>
			<form
				onSubmit={handleSubmit(onEnterCode)}
				className={styles.container}
			>
				{isLoading ? (
					Array(4)
						.fill(0)
						.map((_, idx) => (
							<Skeleton
								key={idx}
								className={styles.loader}
							/>
						))
				) : (
					<>
						{Array(4)
							.fill(0)
							.map((_, index) => {
								return (
									<Controller
										name={`code${index}` as keyof TCodeFormData}
										control={control}
										key={index}
										defaultValue=''
										render={({ field }) => (
											<Input
												key={index}
												pinCode
												maxLength={1}
												{...field}
												type='text'
												disabled={isInputDisabled(index)}
												inputMode='numeric'
												autoComplete='off'
												ref={(el) => (inputRefs.current[index] = el)}
												onKeyDown={handleKeyDown(index)}
												onChange={handleInputChange(index, field.onChange)}
											/>
										)}
									/>
								)
							})}
					</>
				)}
			</form>
			<SendCodeAgain sendCodeAgain={() => {}} />
		</div>
	)
}
