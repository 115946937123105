import { Bounce, ToastPosition, toast } from 'react-toastify'

import { handleErrorMessage, handleErrorStatus } from '../handleError'

export function toastError({ message, error }: { message?: string; error?: any }) {
	const defaultParams = {
		position: 'top-right' as ToastPosition,
		transition: Bounce,
		autoClose: 4000,
		hideProgressBar: false,
		pauseOnHover: true,
		pauseOnFocusLoss: true,
	}
	if (message) {
		toast.error(message, {
			toastId: message,
			...defaultParams,
		})
		return
	}

	if (error) {
		const reserveMessage =
			handleErrorStatus(error) === 500
				? 'Ошибка на сервере, пожалуйста обратитесь к администратору'
				: ''
		const message = handleErrorMessage(error) ?? reserveMessage
		const getMessage = () => {
			if (message) {
				return message
			}
			if ('response' in error && 'data' in error.response) {
				const firstMessage = Object.entries(error.response.data).find((_, idx) => idx === 0)?.[1]
				console.log(firstMessage)
				if (firstMessage) {
					if (Array.isArray(firstMessage)) {
						return firstMessage?.[0]
					}
					return firstMessage
				}
			}
			return 'Неизвестная ошибка. Обратитесь к администратору.'
		}
		toast.error(getMessage(), {
			toastId: getMessage(),
			...defaultParams,
		})
		return
	}

	toast.error('Произошла непредвиденная ошибка', {
		toastId: 'unknown-error',
		...defaultParams,
	})
}
