const timeoutIds: number[] = []
export function useCustomTimeout() {
	function customSetTimeoutFn(fn: () => void, delay: number) {
		const id = window.setTimeout(fn, delay)
		timeoutIds.push(id)
		return id
	}
	function clearAllTimeouts() {
		while (timeoutIds.length) {
			clearTimeout(timeoutIds.pop())
		}
	}
	return { customSetTimeoutFn, clearAllTimeouts }
}
