import clsx from 'clsx'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useEffect } from 'react'

import { ScheduleWorkout } from 'entities/ScheduleWorkout'

import { LogoVector } from 'shared/icons'
import { useResize } from 'shared/lib/useResize'
import { getDayClasses, getRange } from 'shared/lib/utils'
import { useScheduleStore } from 'shared/model'
import { LoaderSchedule } from 'shared/ui/loaders/loaderSchedule/loaderSchedule'
import { Logo } from 'shared/ui/logo'
import { PuffSpinner } from 'shared/ui/puffSpinner'

import './scheduleWeek.scss'

interface IScheduleWeek {
	readonly currentStartRange: dayjs.Dayjs
	readonly isLoading: boolean
}
export const ScheduleWeek: FC<IScheduleWeek> = ({ currentStartRange, isLoading }) => {
	const { LG_SCREEN } = useResize()
	const daysRange = getRange(currentStartRange)
	const Loader = LG_SCREEN ? LoaderSchedule : PuffSpinner
	const { setIsOpenFilters, classes, setSelectedDay, selectedDay, setCurrentStartRange } =
		useScheduleStore(
			({ setIsOpenFilters, classes, setSelectedDay, selectedDay, setCurrentStartRange }) => ({
				setIsOpenFilters,
				classes,
				setSelectedDay,
				selectedDay,
				setCurrentStartRange,
			})
		)

	const isToday = (day: Dayjs) => day.isSame(dayjs(), 'day')
	const hasDayClasses = (day: Dayjs) => {
		const dayClasses = getDayClasses(classes, day)
		return dayClasses.length > 0
	}
	if (LG_SCREEN && !hasDayClasses(selectedDay)) {
		setTimeout(() => {
			let nearDay: dayjs.Dayjs | undefined
			if (currentStartRange.isSame(dayjs(), 'week')) {
				nearDay = daysRange.find(
					(day) => hasDayClasses(day) && (day.isAfter(dayjs(), 'day') || day.isSame(dayjs(), 'day'))
				)
				if (!nearDay) {
					const arrayWithDaysWithClasses = daysRange.filter((day) => hasDayClasses(day))
					nearDay = arrayWithDaysWithClasses[arrayWithDaysWithClasses.length - 1]
				}
			} else {
				nearDay = daysRange.find((day) => hasDayClasses(day))
			}
			if (nearDay) {
				setSelectedDay(nearDay)
			}
		}, 0)
	}
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [selectedDay])
	const isPastDate = (day: Dayjs) => day.isBefore(dayjs(), 'day')
	const isChoosenDay = (day: Dayjs) => day.isSame(selectedDay, 'day') && hasDayClasses(day)

	const renderDayClasses = (day: Dayjs) => {
		const dayClasses = getDayClasses(classes, day)
		return (
			<div key={day.toString()}>
				{dayClasses?.length > 0 && (
					<div className='schedule-week__classes__card fade-in'>
						{dayClasses.map((classData) => (
							<ScheduleWorkout
								key={classData.id}
								classData={classData}
							/>
						))}
					</div>
				)}
				{dayClasses.length === 0 && LG_SCREEN && (
					<div className='schedule-week__classes__empty fade-in'>
						<Logo className='schedule-week__classes__empty__logo' />
						<span>По заданным параметрам ничего не нашлось, измените ваш фильтр тренировок</span>
					</div>
				)}
			</div>
		)
	}

	return (
		<div
			className='schedule-week'
			onClick={() => setIsOpenFilters(false)}
		>
			{LG_SCREEN ? (
				<div className='schedule-week__weekdays-mob'>
					{daysRange.map((day) => (
						<div
							key={day.toString()}
							className={clsx(
								'date',
								isChoosenDay(day) && 'choosen',
								hasDayClasses(day) && 'has-classes'
							)}
							onClick={() => setSelectedDay(day)}
						>
							<p className='day'>{dayjs(day).format('D')}</p>
							<p className='capitalize'>{dayjs(day).format('dd')}</p>
						</div>
					))}
				</div>
			) : (
				<div className='schedule-week__weekdays fade-in'>
					{daysRange.map((day) => (
						<div
							key={day.toString()}
							className={clsx('date', isToday(day) && 'today', !isPastDate(day) && 'present')}
						>
							<div className='schedule-week__day'>
								<span className='capitalize'>{dayjs(day).format('dd, D ')}</span>
								<span>{dayjs(day).format('MMM')}</span>
							</div>
						</div>
					))}
				</div>
			)}
			<div className='schedule-week__classes fade-in'>
				{isLoading ? (
					<Loader />
				) : (
					<>
						{LG_SCREEN
							? renderDayClasses(selectedDay)
							: daysRange.map((day) => renderDayClasses(day))}
					</>
				)}
			</div>
		</div>
	)
}
