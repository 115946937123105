import { UseMutateFunction } from '@tanstack/react-query'
import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { useRedirectToPayMutate } from 'entities/joinClass/api/joinClass'
import { useJoinClassStore } from 'entities/joinClass/model/join-class-store'

import { IJoinClass } from 'shared/api/class'
import { countAvailableSubscribes, isSubscribeAvailableForClass } from 'shared/lib/utils'
import { useSessionStore } from 'shared/model'
import { IRoles } from 'shared/types/classTypes'
import { Button } from 'shared/ui/button'

import { AvailableSubscribes } from './availableSubscribes'
import { RolesList } from './rolesList'
import { UserSubscribes } from './userSubscribes'

import './joinClassInfo.scss'

interface IJoinClassInfo {
	readonly rolesAvailable: IRoles | null
	readonly handleJoinClass: UseMutateFunction<
		IJoinClass,
		Error,
		{
			subscribeId: number
			role?: keyof IRoles
		},
		unknown
	>
	readonly date: string
	readonly isJoinClassLoading: boolean
	readonly classId: number
	readonly className?: string
}

export const JoinClassInfo: FC<IJoinClassInfo> = ({
	handleJoinClass,
	rolesAvailable,
	date,
	isJoinClassLoading,
	classId,
	className,
}) => {
	const {
		userSubscribes,
		errorMessage,
		subscribesForClass,
		setErrorMessage,
		setIsChooseRoleStep,
		isChooseRoleStep,
	} = useJoinClassStore(
		({
			userSubscribes,
			errorMessage,
			subscribesForClass,
			setErrorMessage,
			setIsChooseRoleStep,
			isChooseRoleStep,
		}) => ({
			userSubscribes,
			errorMessage,
			subscribesForClass,
			setIsChooseRoleStep,
			setErrorMessage,
			isChooseRoleStep,
		})
	)
	const defaultChoose =
		subscribesForClass && subscribesForClass.length > 0 ? subscribesForClass[0].id : null
	const [choosenProduct, setChoosenProduct] = useState<number | null>(defaultChoose)
	const [choosenRole, setChoosenRole] = useState<keyof IRoles | null>(null)
	const defaultSubscribe = userSubscribes?.find(
		(sub) => !sub.freeze && isSubscribeAvailableForClass(date, sub.date_end)
	)?.id
	const [choosenSubscribe, setChoosenSubscribe] = useState(defaultSubscribe)
	const { mutate: redirectToPay, isPending: isRedirectToPayLoading } = useRedirectToPayMutate(
		classId,
		choosenRole
	)
	const { session, setIsPopupAccountOpen } = useSessionStore(
		({ session, setIsPopupAccountOpen }) => ({
			session,
			setIsPopupAccountOpen,
		})
	)
	const hasOneActiveSubscribe = countAvailableSubscribes(userSubscribes, date) === 1
	const handleClick = () => {
		if (!session) {
			setIsPopupAccountOpen(true)
			return
		}
		if (isChooseRoleStep) {
			if (choosenSubscribe && choosenRole && hasOneActiveSubscribe) {
				handleJoinClass({ subscribeId: choosenSubscribe, role: choosenRole })
				return
			}
			setIsChooseRoleStep(false)
		} else {
			if (subscribesForClass) {
				if (!choosenProduct) return
				redirectToPay(choosenProduct)
			}

			if (choosenSubscribe) {
				const role = choosenRole ? choosenRole : undefined
				handleJoinClass({ subscribeId: choosenSubscribe, role })
			}
		}
	}
	return (
		<div className={clsx('joinClassInfo-container fade-in', className)}>
			<div className='joinClassInfo-container__content'>
				{errorMessage ? (
					<p className='joinClassInfo-container__content__error'>
						{errorMessage}
						<br />
						{`Для большей информации обратитесь к администратору`}
					</p>
				) : (
					<>
						{userSubscribes && !hasOneActiveSubscribe && !isChooseRoleStep && (
							<UserSubscribes
								subscribes={userSubscribes}
								date={date}
								setChoosenSubscribe={setChoosenSubscribe}
								choosenSubscribe={choosenSubscribe}
							/>
						)}
						{subscribesForClass && !isChooseRoleStep && (
							<AvailableSubscribes
								products={subscribesForClass}
								setChoosenProduct={setChoosenProduct}
								choosenProduct={choosenProduct}
							/>
						)}
						{rolesAvailable && isChooseRoleStep && session && (
							<RolesList
								roles={rolesAvailable}
								setChoosenRole={setChoosenRole}
								choosenRole={choosenRole}
							/>
						)}
					</>
				)}
			</div>
			{errorMessage ? (
				<Link
					className='joinClassInfo-container__link'
					target='_blank'
					to='https://t.me/volleybox_official'
				>
					<Button>Задать вопрос</Button>
				</Link>
			) : (
				session && (
					<Button
						className={clsx('joinClassInfo-container__button')}
						disabled={!choosenRole && !!rolesAvailable && !!session}
						isLoading={isJoinClassLoading || isRedirectToPayLoading}
						onClick={handleClick}
					>
						Записаться
					</Button>
				)
			)}
		</div>
	)
}
