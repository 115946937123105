import { FC } from 'react'
import { Control } from 'react-hook-form'

import { parsePhoneNumber } from 'shared/lib/utils'
import { Field } from 'shared/ui/field'

interface IPhoneInput {
	readonly control: Control<any>
	readonly value: string
	readonly errorMessage?: string
	readonly className?: string
}

export const PhoneInput: FC<IPhoneInput> = ({ control, value, errorMessage, className }) => {
	return (
		<Field
			control={control}
			name={'phone'}
			type='tel'
			label='Номер телефона'
			error={errorMessage}
			autoComplete='on'
			className={className}
			autoFocus
			inputMode='tel'
			maxLength={18}
			controlPattern={{
				value: /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/,
				message: 'Введите действительный номер телефона',
			}}
			value={value}
			onInput={(e) => {
				e.currentTarget.value = parsePhoneNumber(e.currentTarget.value)
			}}
			required={false}
			placeholder='Номер телефона'
		/>
	)
}
