import dayjs from 'dayjs'
import Cookies from 'js-cookie'
import { FC, useEffect, useState } from 'react'

import { STORE_SENDED_CODE_TIME } from 'shared/constants'
import { Button } from 'shared/ui/button'

interface ISendCodeAgain {
	readonly sendCodeAgain: () => void
}

export const SendCodeAgain: FC<ISendCodeAgain> = ({ sendCodeAgain }) => {
	const sendedCodeTime = Cookies.get(STORE_SENDED_CODE_TIME)
	const [remainTime, setRemainTime] = useState(
		sendedCodeTime ? dayjs(sendedCodeTime).add(1, 'minute').diff(dayjs(), 'second') : 0
	)
	useEffect(() => {
		const interval = setInterval(() => {
			if (remainTime > 0) {
				setRemainTime((prev) => prev - 1)
			}
			if (remainTime === 0) {
				clearInterval(interval)
			}
		}, 1000)
		return () => {
			clearInterval(interval)
		}
	}, [remainTime, setRemainTime])
	return (
		<>
			<Button
				type='button'
				theme='link'
				centered
				disabled={remainTime > 0}
				onClick={() => {
					Cookies.set('sendingCode', dayjs().toString(), { expires: 1 / 1440 })
					setRemainTime(60)
					sendCodeAgain()
				}}
			>
				{remainTime > 0
					? `Отправить код повторно можно через ${remainTime} с`
					: 'Отправить еще раз'}
			</Button>
		</>
	)
}
