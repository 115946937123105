import clsx from 'clsx'
import { ForwardedRef, InputHTMLAttributes, forwardRef } from 'react'

import './checkbox.scss'

interface ICheckbox extends InputHTMLAttributes<HTMLInputElement> {
	readonly label: React.ReactNode
}

const CheckboxComponent = (props: ICheckbox, ref: ForwardedRef<HTMLInputElement>) => {
	const { className, label, ...rest } = props
	return (
		<label className={clsx('field-checkbox', className)}>
			<input
				{...rest}
				type={'checkbox'}
				ref={ref}
			/>
			<span className='field-checkbox__checkmark'></span>
			{label}
		</label>
	)
}

export const Checkbox = forwardRef(CheckboxComponent)
