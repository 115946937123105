import { FC, useState } from 'react'
import { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { Outlet } from 'react-router-dom'
import { Flip, ToastContainer } from 'react-toastify'
import { Toaster } from 'sonner'

import { Account } from 'pages/account'

import { Footer } from 'widgets/Footer'
import { Header } from 'widgets/Header'
import { Notifications } from 'widgets/notifications'

import { Theme, useSessionStore, useThemeContext } from 'shared/model'

import './layout.scss'

interface ILayout {
	readonly children?: React.ReactNode
}

export const Layout: FC<ILayout> = ({ children }) => {
	const { isPopupAccountOpen } = useSessionStore(({ isPopupAccountOpen }) => ({
		isPopupAccountOpen,
	}))

	const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)
	const closeNotifications = () => {
		document.body.style.overflow = ''
		setIsNotificationsOpen(false)
	}

	const openNotifications = () => {
		document.body.style.overflow = 'hidden'
		setIsNotificationsOpen(true)
	}
	const fakeData = [
		{
			group: 'workout',
			title: 'Напоминание о тренировке',
			message: '14 октября 22:00',
		},
		{
			group: 'subscribe',
			title: 'Абонемент',
			message: 'У вас осталось 2 тренировки',
		},
		{
			group: 'change',
			title: 'Замена',
			message: 'Замена тренера. 14 октября тренировку проведет Котов Андрей.',
		},
		{
			group: 'subscribe',
			title: 'Абонемент',
			message: 'У вас осталось 2 тренировки',
		},
		{
			group: 'subscribe',
			title: 'Абонемент',
			message: 'У вас осталось 2 тренировки',
		},
		{
			group: 'subscribe',
			title: 'Абонемент',
			message: 'У вас осталось 3 тренировки',
		},
		{
			group: 'subscribe',
			title: 'Абонемент',
			message: 'У вас осталось 4 тренировки',
		},
		{
			group: 'subscribe',
			title: 'Абонемент',
			message: 'У вас осталось 5 тренировок',
		},
		{
			group: 'subscribe',
			title: 'Абонемент',
			message: 'У вас осталось 6 тренировок',
		},
	] as {
		group: 'workout' | 'subscribe' | 'change'
		title: string
		message: string
	}[]
	const theme = useThemeContext((state) => state.theme)
	const isDarkMode = theme === Theme.DARK
	return (
		<div className='layout'>
			<SkeletonTheme
				baseColor='var(--skeleton-main)'
				highlightColor='var(--skeleton-highlight)'
			>
				<Header
					openNotifications={openNotifications}
					countNotifications={fakeData.length}
				/>

				<main className='main'>{children ?? <Outlet />}</main>

				<Footer />
				{isPopupAccountOpen && <Account />}
				<Notifications
					fakeData={fakeData}
					isNotificationsOpen={isNotificationsOpen}
					handleClose={closeNotifications}
				/>
			</SkeletonTheme>
			<Toaster
				richColors
				position='bottom-center'
				visibleToasts={1}
			/>
			<ToastContainer
				position='top-center'
				autoClose={2000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover={false}
				theme={isDarkMode ? 'dark' : 'light'}
				transition={Flip}
			/>
		</div>
	)
}
