import clsx from 'clsx'
import { FC } from 'react'

import { RangeSwitcher } from 'widgets/rangeSwitcher'

import { FilterManage } from 'features/schedule/filterManage'

import { CloseIcon, FilterIcon } from 'shared/icons'
import { useResize } from 'shared/lib/useResize'
import { useScheduleStore } from 'shared/model'
import { Button } from 'shared/ui/button'
import { CountTip } from 'shared/ui/countTip'

import './scheduleHeader.scss'

interface IScheduleHeader {}
export const ScheduleHeader: FC<IScheduleHeader> = () => {
	const { isOpenFilters, setIsOpenFilters, filters, setFilters } = useScheduleStore(
		({ isOpenFilters, setIsOpenFilters, filters, setFilters }) => ({
			isOpenFilters,
			setIsOpenFilters,
			filters,
			setFilters,
		})
	)
	const activeFiltersCount = filters.gym.length + filters.trainer.length + filters.classLevel.length
	const { MD_SCREEN } = useResize()
	return (
		<section
			className={'schedule-header'}
			id='schedule-header'
		>
			{MD_SCREEN && (
				<article className='schedule-header__title'>
					<h1>Расписание</h1>
					<div className='schedule-header__actions'>
						<Button
							theme='red'
							className={clsx('schedule-header__clean-filters', activeFiltersCount > 0 && 'active')}
							onClick={(e) => {
								e.stopPropagation()
								setFilters({ gym: [], trainer: [], classLevel: [] })
							}}
						>
							<CloseIcon />
						</Button>
						<Button
							onClick={(e) => {
								e.stopPropagation()
								setIsOpenFilters(!isOpenFilters)
							}}
							className={clsx('schedule-header__trigger-filters', isOpenFilters && 'active')}
							theme='transparent-green'
						>
							<FilterIcon />
							{activeFiltersCount > 0 && <CountTip count={activeFiltersCount} />}
						</Button>
					</div>
				</article>
			)}
			<article className='schedule-header__bar'>
				<RangeSwitcher />
				{!MD_SCREEN && <FilterManage />}
			</article>
		</section>
	)
}
