import clsx from 'clsx'
import { FC } from 'react'

import defaultAvt from 'shared/assets/fetisovaDefault.jpg'
import {
	BiometryIcon,
	DropIcon,
	HighBowlIcon,
	LocationIcon,
	ParkingIcon,
	PhoneIcon,
	TelegramFillIcon,
	TrainerIcon,
} from 'shared/icons'
import { WhatsAppIcon } from 'shared/icons/whatsAppIcon'
import { useResize } from 'shared/lib/useResize'
import { IClassById } from 'shared/types'
import { IconTitle } from 'shared/ui/iconTitle'

import { GroupItem } from './groupItem'

import './workoutFeatures.scss'

interface IWorkoutDetailFeatures {
	readonly workout: IClassById
}

export const WorkoutDetailFeatures: FC<IWorkoutDetailFeatures> = ({ workout }) => {
	const contacts = [
		{ title: '+7 (495) 000-00-00', Icon: PhoneIcon },
		{ title: '8 (800) 000-00-00', Icon: WhatsAppIcon },
		{ title: '@volleybox', Icon: TelegramFillIcon },
	]
	const about = [
		{ title: 'Проходная система', Icon: BiometryIcon },
		{ title: 'Есть душ', Icon: DropIcon },
		{ title: 'Есть кулер с водой', Icon: HighBowlIcon },
		{ title: 'Есть парковка', Icon: ParkingIcon },
	]

	return (
		<article className={clsx('workout-detail')}>
			<GroupItem title='Адрес'>
				<IconTitle
					Icon={LocationIcon}
					title={workout.gym.address}
					gap={12}
				/>
			</GroupItem>
			<GroupItem title='Тренер'>
				<IconTitle
					Icon={TrainerIcon}
					title={`${workout.trainer.last_name} ${workout.trainer.first_name}`}
					gap={12}
				/>
			</GroupItem>

			<GroupItem title='Условия'>
				{about.map(({ title, Icon }) => (
					<IconTitle
						key={title}
						Icon={Icon}
						title={title}
						gap={12}
					/>
				))}
			</GroupItem>

			<GroupItem title='Контакты'>
				{contacts.map(({ title, Icon }) => (
					<IconTitle
						key={title}
						Icon={Icon}
						title={title}
						gap={12}
					/>
				))}
			</GroupItem>
		</article>
	)
}
