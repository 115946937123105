import clsx from 'clsx'
import { FC, InputHTMLAttributes, useRef, useState } from 'react'
import { Control, Controller, ValidationRule } from 'react-hook-form'

import { EyeIcon } from 'shared/icons'

import styles from './field.module.scss'

interface IField extends InputHTMLAttributes<HTMLInputElement> {
	readonly control: Control<any>
	readonly label: string
	readonly error?: string | null
	readonly controlPattern?: ValidationRule<RegExp> | undefined
	readonly validate?: (value: string) => boolean | string
	readonly name: string
}

export const Field: FC<IField> = ({
	control,
	name,
	placeholder,
	required = false,
	label,
	type = 'text',
	error,
	className,
	controlPattern,
	validate,
	...rest
}) => {
	const [isPasswordShown, setIsPasswordShown] = useState(false)
	const inputRef = useRef<HTMLInputElement | null>(null)
	return (
		<Controller
			name={name}
			control={control}
			rules={{
				pattern: controlPattern,
				validate: validate ? (value) => validate(value) : undefined,
			}}
			render={({ field }) => (
				<div className={clsx(styles['field-control'], className)}>
					<label>
						<input
							id={name}
							type={isPasswordShown ? 'text' : type}
							className={styles.input}
							placeholder={placeholder}
							data-type={type === 'password' ? 'password' : undefined}
							required={required}
							aria-invalid={error ? 'true' : undefined}
							{...rest}
							{...field}
							ref={(e) => {
								field.ref(e)
								inputRef.current = e
							}}
							value={field.value || ''}
						/>
						<div className={clsx(styles.label, error && 'red-highlight')}>
							{error ? error : label}
						</div>
						{type === 'password' && (
							<button
								type='button'
								className={styles.eye}
								tabIndex={-1}
								aria-label={isPasswordShown ? 'Скрыть пароль' : 'Показать пароль'}
								onClick={(e) => {
									e.stopPropagation()
									e.preventDefault()
									setIsPasswordShown(!isPasswordShown)
									inputRef.current?.blur()
								}}
							>
								<EyeIcon shown={isPasswordShown} />
							</button>
						)}
					</label>
				</div>
			)}
		/>
	)
}
