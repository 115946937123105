import clsx from 'clsx'
import { FC } from 'react'

import { PlayersSquad } from 'widgets/playersSquad'

import { IClassById, IPlayer } from 'shared/types'
import { ProgressBar } from 'shared/ui/progressBar'

import './workoutSquad.scss'

interface IWorkoutSquad {
	readonly workout: IClassById
}

export const WorkoutSquad: FC<IWorkoutSquad> = ({ workout }) => {
	const players = [
		...workout.players,
		{ first_name: 'Анастасия', last_name: 'Фетисова', id: 9997 },
		{ first_name: 'Даниил', last_name: 'Торопов', id: 9998 },
		{ first_name: 'Дмитрий', last_name: 'Иванов', id: 9996 },
		{ first_name: 'Оксана', last_name: 'Черемисина', id: 9995 },
		{ first_name: 'Михайлов', last_name: 'Антон', id: 9994 },
		{ first_name: 'Абудрахман-оглы-азан', last_name: 'Евдокий-Аллах', id: 99 },
		{ first_name: 'Майка', last_name: 'Кристенсон', id: 9993 },
		{ first_name: 'Алексей', last_name: 'Вербов', id: 9992 },
		{ first_name: 'Владимир', last_name: 'Путин', id: 9991 },
		{ first_name: 'Остап', last_name: 'Юрьев', id: 9989 },
		{ first_name: 'Елена', last_name: 'Искрова', id: 9990 },
		{ first_name: 'Светлана', last_name: 'Ходченкова', id: 9988 },
	] as IPlayer[]

	return (
		<article className={clsx('workout-squad hide-scroll')}>
			<div className='workout-squad__progress'>
				<ProgressBar
					current={players.length}
					max={workout.limit}
					height={40}
				/>
			</div>
			<PlayersSquad players={players} />
		</article>
	)
}
