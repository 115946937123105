import { FC, useState } from 'react'

import { PasswordRecovery } from 'widgets/passwordRecovery'
import { Register } from 'widgets/register'
import { SignIn } from 'widgets/signIn'

import { useSessionStore } from 'shared/model'
import { TPopupAccountMode } from 'shared/types'
import { Modal } from 'shared/ui/modal'

interface IAccount {}

export const Account: FC<IAccount> = () => {
	const [mode, setMode] = useState<TPopupAccountMode>('login')
	const { isPopupAccountOpen, setIsPopupAccountOpen } = useSessionStore(
		({ isPopupAccountOpen, setIsPopupAccountOpen }) => ({
			isPopupAccountOpen,
			setIsPopupAccountOpen,
		})
	)
	const components = {
		'password-recovery': () => <PasswordRecovery handleChangeMode={handleChangeMode} />,
		login: () => <SignIn handleChangeMode={handleChangeMode} />,
		register: () => <Register onChangeMode={handleChangeMode} />,
	} as { [key in TPopupAccountMode]: () => React.ReactElement }
	/*** Handlers ***/
	const handleClosePopup = () => {
		setIsPopupAccountOpen(false)
	}
	function handleChangeMode(mode: TPopupAccountMode) {
		setMode(mode)
	}
	const getModalTitle = () => {
		switch (mode) {
			case 'login':
				return 'Вход'
			case 'register':
				return 'Регистрация'
			case 'password-recovery':
				return 'Сброс пароля'
		}
	}

	const Component = components[mode]
	return (
		<Modal
			onClose={handleClosePopup}
			closeButton
			isHighPriority
			title={getModalTitle()}
		>
			<Component />
		</Modal>
	)
}
