import clsx from 'clsx'
import { FC, TouchEvent, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { Tabs } from 'widgets/tabs/ui/tabs'

import { useElementOnScreen } from 'shared/lib/useElementOnScreen'
import { useResize } from 'shared/lib/useResize'
import { IClassById } from 'shared/types'
import { Tab } from 'shared/ui/tab'

import { WorkoutDetailFeatures } from '../../workoutDetailFeatures/workoutFeatures'
import { WorkoutPhoto } from '../../workoutDetailPhoto/workoutPhoto'
import { WorkoutSquad } from '../../workoutSquad/workoutSquad'

import './detailTabs.scss'

interface IDetailTabs {
	readonly workout: IClassById
}
const timeoutIds: number[] = []
export const DetailTabs: FC<IDetailTabs> = ({ workout }) => {
	const location = useLocation()
	/** const  */
	const tabsKeys = ['composition', 'details'] as const
	const tabsNames = {
		details: 'Подробности',
		photo: 'Фото',
		composition: 'Состав',
	}
	type TTabs = (typeof tabsKeys)[number]
	const tabs = {
		photo: WorkoutPhoto,
		composition: WorkoutSquad,
		details: WorkoutDetailFeatures,
	}
	const searchParams = new URLSearchParams(location.search)
	const currentTab = (searchParams.get('tab') as TTabs) || tabsKeys[0]
	const currentActiveTab = tabsKeys.includes(currentTab) ? currentTab : tabsKeys[0]
	const currentActiveTabIndex = getTabIndex(currentActiveTab)

	const hasTouch =
		(typeof window !== 'undefined' && 'ontouchstart' in window) || navigator.maxTouchPoints > 0
	/** Hooks */

	const [activeTab, setActiveTab] = useState(currentActiveTabIndex)
	const [touchPosition, setTouchPosition] = useState<{ x: number; y: number } | null>(null)
	const [fastSwipe, setFastSwipe] = useState(false)
	const [pointBlock, setPointBlock] = useState<number | null>(null)
	const [isConnectObserver, setIsConnectObserver] = useState(false)
	const { ref: startBlockRef, isVisible: isVisibleStartBlock } =
		useElementOnScreen(isConnectObserver)
	const { ref: endBlockRef, isVisible: isVisibleEndBlock } = useElementOnScreen(isConnectObserver)
	const { LG_SCREEN } = useResize()
	/** Refs */
	const tabsRef = useRef<HTMLDivElement | null>(null)
	const prevActiveTabIndex = useRef<number>(currentActiveTabIndex)
	function customSetTimeoutFn(fn: () => void, delay: number) {
		const id = window.setTimeout(fn, delay)
		timeoutIds.push(id)
		return id
	}
	function customClearTimeoutFn() {
		while (timeoutIds.length) {
			clearTimeout(timeoutIds.pop())
		}
	}
	const customSetTimeout = useRef(customSetTimeoutFn)
	const clearAllTimeouts = useRef(customClearTimeoutFn)

	/** Effects */

	function getTabIndex(key: TTabs) {
		return tabsKeys.indexOf(key)
	}
	const handleTabChange = (tab: number) => {
		clearAllTimeouts.current()
		prevActiveTabIndex.current = activeTab
		const prevActiveRef = tabsRef.current?.querySelector('.active')
		setActiveTab(tab)
		const key = tabsKeys[tab]
		if (prevActiveRef) {
			customSetTimeout.current(() => {
				prevActiveRef.scrollTop = 0
			}, 200)
		}
		searchParams.set('tab', key)
		window.history.replaceState({}, '', `${location.pathname}?${searchParams.toString()}`)
	}
	function handleTouchStart(e: TouchEvent<HTMLDivElement>) {
		if (!hasTouch) return
		if (!isConnectObserver) {
			setIsConnectObserver(true)
		}
		setFastSwipe(true)
		clearAllTimeouts.current()
		customSetTimeout.current(() => {
			setFastSwipe(false)
		}, 500)
		const { clientX, clientY } = e.touches[0]
		setTouchPosition({ x: clientX, y: clientY })
	}
	function handleTouchMove(e: TouchEvent<HTMLDivElement>) {
		if (!hasTouch || touchPosition === null) return
		const { clientX: currentXPosition, clientY } = e.touches[0]
		const { x: touchX, y: touchY } = touchPosition

		const diff = touchX - currentXPosition
		if (tabsRef && tabsRef.current) {
			if (Math.abs(touchY - clientY) > 20) {
				tabsRef.current.style.setProperty('--transition-duration', '0.3s')
				tabsRef.current.style.transform = `translate3d(calc(-${activeTab * 100}%), 0, 0)`
				setTouchPosition(null)
				return
			}
			let transform = 0
			if (!pointBlock && (isVisibleEndBlock || isVisibleStartBlock)) {
				setPointBlock(diff)
			}
			const position = activeTab + diff / tabsRef.current.clientWidth
			tabsRef.current.style.setProperty('--position', position.toString())
			if (diff > 0) {
				if (activeTab === tabsKeys.length - 1) return
				transform = isVisibleEndBlock && pointBlock ? Math.min(pointBlock + 30, diff) : diff
			} else {
				if (activeTab === 0) return
				transform = isVisibleStartBlock && pointBlock ? Math.max(pointBlock, diff) : diff
			}
			tabsRef.current.style.setProperty('--transition-duration', '0s')
			tabsRef.current.style.transform = `translate3d(calc(-${
				activeTab * 100
			}% - ${activeTab}px - ${transform}px), 0, 0)`
		}
	}
	const handleTouchEnd = (e: TouchEvent) => {
		if (!hasTouch || touchPosition === null) return
		const touchEnd = e.changedTouches[0].clientX
		const { x: touchX } = touchPosition
		if (tabsRef && tabsRef.current) {
			tabsRef.current.style.setProperty('--transition-duration', '0.3s')
			if (fastSwipe) {
				if (touchX - touchEnd > 20) {
					if (activeTab !== tabsKeys.length - 1) {
						handleTabChange(activeTab + 1)
						return
					}
				}
				if (touchX - touchEnd < -20) {
					if (activeTab !== 0) {
						handleTabChange(activeTab - 1)
						return
					}
				}
				setFastSwipe(false)
			}

			const position = activeTab + (touchX - touchEnd) / tabsRef.current.clientWidth
			const calcIndex = Math.round(position)
			if (calcIndex !== activeTab) {
				handleTabChange(calcIndex)
			}

			tabsRef.current.style.transform = `translate3d(calc(-${calcIndex * 100}%), 0, 0)`
			tabsRef.current.style.setProperty('--position', calcIndex.toString())
		}
		setTouchPosition(null)
		setPointBlock(null)
	}
	return (
		<Tabs
			tabs={tabsKeys.map((tab) => tabsNames[tab])}
			activeTab={activeTab}
			handleChangeTab={handleTabChange}
			prevActiveTab={prevActiveTabIndex.current}
		>
			<div
				className='tabs-details'
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onTouchEnd={handleTouchEnd}
			>
				<div className='tabs-details__content'>
					<div
						ref={tabsRef}
						className={'tabs-details__content-wrapper'}
						style={{
							transform: `translate3d(calc(-${activeTab * 100}%), 0, 0)`,
						}}
					>
						<div
							className={'tabs-details__content-observer'}
							ref={startBlockRef}
						/>

						<>
							{tabsKeys.map((tab, idx) => {
								const TabComponent = tabs[tab]
								return (
									<div
										key={tab}
										className={clsx(
											'tabs-details__content-wrapper__item hide-scroll',
											activeTab === idx && 'active'
										)}
									>
										<TabComponent workout={workout} />
									</div>
								)
							})}
						</>
						<div
							ref={endBlockRef}
							style={{
								transform: `translate(calc(${tabsKeys.length * 100}% + 30px), 0)`,
							}}
							data-position='end'
							className={'tabs-details__content-observer'}
						/>
					</div>
				</div>
			</div>
		</Tabs>
	)
}
