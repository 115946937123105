import { FC } from 'react'

import './workoutFeatures.scss'

interface IGroupItem {
	readonly children: React.ReactNode
	readonly title: string
}

export const GroupItem: FC<IGroupItem> = ({ children, title }) => {
	return (
		<div className='workout-detail__group'>
			<h6 className='workout-detail__group__title'>{title}</h6>
			{children}
		</div>
	)
}
