import { useQuery } from '@tanstack/react-query'
import clsx from 'clsx'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'

import { ScheduleFilter } from 'widgets/scheduleFilter'
import { ScheduleHeader } from 'widgets/scheduleHeader'
import { ScheduleWeek } from 'widgets/scheduleWeek'

import { toastError } from 'shared/lib/handleError'
import { useResize } from 'shared/lib/useResize'
import { useRange } from 'shared/lib/utils'
import { useScheduleStore, useSessionStore } from 'shared/model'

import { useClassesQuery } from '../api/classes'
import { classLevelOptions, gymsOptions, trainersOptions } from '../api/filter'

import './SchedulePage.scss'

dayjs.extend(updateLocale)

dayjs.updateLocale('ru', {
	monthsShort: ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
})
export const SchedulePage = () => {
	const { session, setSession } = useSessionStore(({ session, setSession }) => ({
		session,
		setSession,
	}))
	const {
		isOpenFilters,
		requestParams,
		setClasses,
		setIsOpenFilters,
		setSelectedDay,
		setCurrentStartRange,
		selectedDay,
	} = useScheduleStore(
		({
			requestParams,
			isOpenFilters,
			setClasses,
			setIsOpenFilters,
			setSelectedDay,
			setCurrentStartRange,
			selectedDay,
		}) => ({
			requestParams,
			isOpenFilters,
			setClasses,
			setIsOpenFilters,
			setSelectedDay,
			setCurrentStartRange,
			selectedDay,
		})
	)
	const {
		data: levels,
		isLoading: isLoadingLevels,
		error: errorLevels,
	} = useQuery(classLevelOptions)
	const {
		data: trainers,
		isLoading: isLoadingTrainers,
		error: errorTrainers,
	} = useQuery(trainersOptions)
	const { data: gyms, isLoading: isLoadingGyms, error: errorGyms } = useQuery(gymsOptions)
	const { LG_SCREEN, MD_SCREEN } = useResize()
	const isSuccessLoadingFilters = gyms && levels && trainers
	const {
		data: rangeClasses,
		isLoading: isLoadingClasses,
		isSuccess,
	} = useClassesQuery(requestParams, session?.id || 'guest')
	useEffect(() => {
		if (errorTrainers) toastError(errorTrainers, 'Ошибка загрузки тренеров')
		if (errorLevels) toastError(errorLevels, 'Ошибка загрузки уровней')
		if (errorGyms) toastError(errorGyms, 'Ошибка загрузки залов')
	}, [errorGyms, errorLevels, errorTrainers])

	useEffect(() => {
		if (isSuccess) {
			setClasses(rangeClasses)
		}
	}, [isSuccess, setClasses, rangeClasses])
	const { currentStartRange } = useRange()
	const stringRange = currentStartRange.toString()

	const [searchParams, setSearchParams] = useSearchParams()
	const encryptedWorkout = searchParams.get('date')
	if (encryptedWorkout) {
		let decryptedDate = ''
		try {
			decryptedDate = window.atob(encryptedWorkout)
		} catch (e) {
			const newSearchParams = new URLSearchParams(window.location.search)
			newSearchParams.delete('workout')
			newSearchParams.delete('date')
			toast.error('Тренировка не найдена', { description: 'Невалидные данные, проверьте ссылку' })
			setSearchParams(newSearchParams)
		}
		if (decryptedDate) {
			const date = dayjs(decryptedDate)
			if (!date.isoWeekday(1).isSame(currentStartRange, 'day')) {
				setCurrentStartRange(date.isoWeekday(1))
			}
			if (LG_SCREEN && !selectedDay.isSame(date, 'day')) {
				setSelectedDay(dayjs(date))
			}
		}
	}
	const isLoadingFilters = isLoadingLevels || isLoadingTrainers || isLoadingGyms
	const isLoading = isLoadingLevels || isLoadingTrainers || isLoadingGyms || isLoadingClasses
	useEffect(() => {
		window.scroll({
			behavior: 'smooth',
			top: 0,
			left: 0,
		})
	}, [stringRange, LG_SCREEN])

	return (
		<div className='schedule'>
			<ScheduleHeader />
			<section
				id='workouts'
				className={clsx('schedule__content', isOpenFilters && 'filters-open')}
			>
				<>
					<div
						onClick={() => setIsOpenFilters(false)}
						className={clsx('schedule__overlay', isOpenFilters && MD_SCREEN && 'filters-open')}
					/>
					{isSuccessLoadingFilters && (
						<ScheduleFilter
							isLoading={isLoadingFilters}
							gyms={gyms}
							levels={levels}
							trainers={trainers}
						/>
					)}
					<ScheduleWeek
						currentStartRange={currentStartRange}
						isLoading={isLoadingClasses}
					/>
				</>
			</section>
		</div>
	)
}
