import { useEffect, useRef, useState } from 'react'

export const useElementOnScreen = (connect = true, options?: IntersectionObserverInit) => {
	const ref = useRef<HTMLDivElement | null>(null)
	const [isVisible, setIsVisible] = useState(false)
	const callbackFunction: IntersectionObserverCallback = (entries) => {
		const [entry] = entries
		setIsVisible(entry.isIntersecting)
	}

	useEffect(() => {
		const observer = new IntersectionObserver(callbackFunction, options)
		if (ref.current && connect) observer.observe(ref.current)
		return () => {
			observer.disconnect()
		}
	}, [ref, options, connect])
	return { ref, isVisible }
}
