import clsx from 'clsx'
import { FC } from 'react'

import defaultAvt from 'shared/assets/man-3.webp'
import { generateRandomHSL } from 'shared/lib/generateRandomHSL'
import { sortPlayers } from 'shared/lib/utils'
import { useSessionStore } from 'shared/model'
import { IPlayer } from 'shared/types'

import './playerCard.scss'

interface IPlayerCard {
	readonly player: IPlayer
	readonly className?: string
}

export const PlayerCard: FC<IPlayerCard> = ({ player, className }) => {
	const session = useSessionStore((state) => state.session)
	const currentPlayer = session?.id === player.id

	const name = `${player.last_name} ${player.first_name}`
	const randomColor = generateRandomHSL(name)
	return (
		<article className={clsx('player-card', className, currentPlayer && 'current-player')}>
			<div
				className='player-card__avatar'
				style={{ backgroundColor: randomColor }}
			>
				<span>
					{player.first_name.charAt(0).toUpperCase() + player.last_name.charAt(0).toUpperCase()}
				</span>
			</div>
			<div className='player-card__info'>
				{currentPlayer ? (
					<p>{name} (Вы)</p>
				) : (
					<>
						<p>{name}</p>
					</>
				)}
				{player.role && <span>{player.role}</span>}
			</div>
		</article>
	)
}
