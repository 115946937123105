import { Keyframes } from '@emotion/react'
import clsx from 'clsx'
import { FC, useEffect, useRef } from 'react'

import './tabs.scss'

interface ITabs {
	readonly tabs: readonly string[]
	readonly activeTab: number
	readonly prevActiveTab: number
	readonly handleChangeTab: (tab: number) => void
	readonly children: React.ReactNode
	readonly sticky?: boolean
}
export const Tabs: FC<ITabs> = ({
	tabs,
	activeTab,
	handleChangeTab,
	children,
	prevActiveTab,
	sticky,
}) => {
	/** Refs */
	const tabsRefs = useRef<(HTMLSpanElement | null)[]>([])
	/** Effects */

	useEffect(() => {
		const prevActiveTabRef = tabsRefs.current[prevActiveTab]
		const activeTabRef = tabsRefs.current[activeTab]
		if (prevActiveTabRef && activeTabRef) {
			const transformSize = tabsRefs.current.reduce((acc, ref, index) => {
				if (activeTab > prevActiveTab) {
					if (index >= prevActiveTab && index < activeTab) {
						return acc + (ref?.offsetWidth || 0)
					} else {
						return acc
					}
				} else {
					if (index >= activeTab && index < prevActiveTab) {
						return acc + (ref?.offsetWidth || 0)
					} else {
						return acc
					}
				}
			}, 0)
			const { offsetLeft: offsetLeftPrev } = prevActiveTabRef
			const { offsetLeft: offsetLeftActive } = activeTabRef

			const transformPosition =
				offsetLeftActive - offsetLeftPrev > 0 ? `-${transformSize}` : transformSize
			const indicatorRef = activeTabRef.querySelector('i')
			const activeTabWidth = activeTabRef.querySelector('span')?.offsetWidth
			const prevActiveTabWidth = prevActiveTabRef.querySelector('span')?.offsetWidth
			if (indicatorRef) {
				const transform = [
					{
						transform: `translateX(${transformPosition}px)`,
						width: `${prevActiveTabWidth}px`,
					},
					{ transform: 'translateX(0)', width: `${activeTabWidth}px` },
				]
				const timing = { duration: 200 }
				indicatorRef.animate(transform, timing)
			}
		}
	}, [activeTab, prevActiveTab])
	return (
		<div className='tabs'>
			<div className={clsx('tabs-navigation-wrapper hide-scroll', sticky && 'sticky')}>
				<div className='tabs-navigation-menu'>
					{tabs.map((tab, index) => (
						<button
							key={tab}
							className={clsx('tabs-navigation-menu-item', activeTab === index && 'active')}
							onClick={() => handleChangeTab(index)}
							ref={(el) => (tabsRefs.current[index] = el)}
						>
							<span className='tabs-navigation-menu-item-content'>
								{tab}
								<i />
							</span>
						</button>
					))}
				</div>
			</div>
			{children}
		</div>
	)
}
