import clsx from 'clsx'
import { FC } from 'react'
import { IconBaseProps } from 'react-icons'
import { FaArrowLeft } from 'react-icons/fa6'

import styles from './backButton.module.scss'

interface IBackButton {
	readonly handleBack: () => void
	readonly className?: string
}
const BackIcon = FaArrowLeft as React.ComponentType<IconBaseProps>
export const BackButton: FC<IBackButton> = ({ handleBack, className }) => {
	return (
		<button
			onClick={handleBack}
			aria-label='Назад'
			className={clsx(styles.back, className)}
		>
			<BackIcon />
		</button>
	)
}
