import clsx from 'clsx'
import { ButtonHTMLAttributes, ForwardedRef, forwardRef } from 'react'

import styles from './button.module.scss'

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
	readonly theme?:
		| 'primary'
		| 'transparent'
		| 'green'
		| 'transparent-green'
		| 'red'
		| 'black'
		| 'black-green'
		| 'link'
	readonly isLoading?: boolean
	readonly bold?: boolean
	readonly centered?: boolean
	readonly link?: boolean
	readonly Icon?: React.JSXElementConstructor<React.SVGProps<SVGSVGElement>>
	readonly fullWidth?: boolean
}

const ButtonComponent = (props: IButton, ref: ForwardedRef<HTMLButtonElement>) => {
	const {
		children,
		className,
		theme = 'primary',
		type = 'button',
		isLoading,
		bold = false,
		centered = false,
		Icon,
		link,
		fullWidth,
		disabled,
		...rest
	} = props
	return (
		<button
			type={type}
			{...rest}
			ref={ref}
			disabled={disabled || isLoading}
			className={clsx(
				styles.button,
				styles[theme],
				className,
				centered && styles.centered,
				fullWidth && styles['full-width'],
				bold && styles.bold
			)}
		>
			{isLoading ? <span className={styles.loader}></span> : children}
			{Icon && <Icon className={styles.icon} />}
		</button>
	)
}
export const Button = forwardRef(ButtonComponent)

Button.displayName = 'Button'
