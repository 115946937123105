import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import ErrorPage from 'components/ErrorPage/ErrorPage'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import { FC } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { Fallback } from 'shared/ui/fallback/fallback'

dayjs.extend(updateLocale)

dayjs.updateLocale('ru', {
	monthsShort: ['янв', 'фев', 'мар', 'апр', 'мая', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
})
interface IProviders {
	readonly children: React.ReactNode
}

export const Providers: FC<IProviders> = ({ children }) => {
	const queryClient = new QueryClient()
	return (
		<QueryClientProvider client={queryClient}>
			<ReactQueryDevtools />
			<ErrorBoundary fallback={<Fallback />}>{children}</ErrorBoundary>
		</QueryClientProvider>
	)
}
