import { useMutation } from '@tanstack/react-query'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useOutletContext } from 'react-router-dom'

import { ProfileSettingsContacts } from 'widgets/profileSettings/contacts'
import { MainInfoSettings } from 'widgets/profileSettings/mainInfo'
import { ProfileSecondaryInfo } from 'widgets/profileSettings/secondaryInfo'

import { ISettingFields, IUserData, updateUserProfile } from 'shared/api/account'
import defaultAvatar from 'shared/assets/man-1.webp'
import { ArrowBlackBg } from 'shared/icons'
import { handleErrorStatus, toastError } from 'shared/lib/handleError'
import { useResize } from 'shared/lib/useResize/useResize'
import { formatPhone } from 'shared/lib/utils'
import { Button } from 'shared/ui/button'
import { Modal } from 'shared/ui/modal'

import './profileSettings.scss'

export const ProfileSettings = () => {
	const { userProfile, handleOpenNavigation } = useOutletContext() as {
		userProfile: IUserData
		handleOpenNavigation: () => void
	}
	const [isModalOpen, setIsModalOpen] = useState(false)
	const roles = userProfile.role?.map(({ id, name }) => ({
		value: id,
		label: name,
	}))
	const defaultValues = {
		first_name: userProfile.first_name,
		about: userProfile.about ?? '',
		avatar: undefined,
		birthday: userProfile.birthday ?? '',
		experience: userProfile.experience ?? '',
		height: userProfile.height ?? '',
		last_name: userProfile.last_name,
		patronymic: userProfile.patronymic ?? '',
		phone: formatPhone(userProfile.phone ?? ''),
		show_my_birthday: userProfile.show_my_birthday ?? false,
		show_my_name: userProfile.show_my_name ?? false,
		telegram: userProfile.telegram ?? '',
		role: roles,
	} as ISettingFields
	const { mutate: updateUserData, isPending } = useMutation({
		mutationKey: ['updateUserData'],
		mutationFn: (data: ISettingFields) => updateUserProfile(data),
		onSuccess: () => {
			setIsModalOpen(true)
		},
		onError: (error: unknown) => {
			if (handleErrorStatus(error) === 400) {
				const knownError = error as {
					response: { data: { [K in keyof ISettingFields]?: string[] } }
				}
				const { data } = knownError.response
				const entries = Object.entries(data) as [keyof ISettingFields, string[]][]
				entries.forEach(([key, value]) => {
					setError(key, { message: value[0] })
				})
			} else {
				toastError(error, 'Error updating profile')
			}
		},
	})
	const {
		control,
		handleSubmit,
		setValue,
		setError,
		formState: { errors },
		watch,
	} = useForm<ISettingFields>({
		defaultValues,
	})

	const handleClose = () => {
		setIsModalOpen(false)
	}
	const onSubmit = handleSubmit((data) => {
		const formattedPhone = formatPhone(data.phone, 'numbers')
		if (formattedPhone.length < 12) {
			setError('phone', { message: 'Введите корректный номер' })
			return
		}
		data.phone = formattedPhone
		const choosenRoles = data.role.map(({ value, label }) => ({
			id: value,
			name: label,
		}))
		data.role = choosenRoles
		updateUserData(data)
	})
	const { isXLargeScreen } = useResize()
	return (
		<div className='profile-settings'>
			<form
				className='profile-settings__form'
				onSubmit={onSubmit}
			>
				<div className='profile-settings__header profile__sticky'>
					<div className='profile__header-title'>
						{!isXLargeScreen && (
							<button
								type='button'
								onClick={handleOpenNavigation}
							>
								<ArrowBlackBg />
							</button>
						)}
						<h1 className='profile-settings__title'>Настройки профиля</h1>
					</div>
					<div className='profile-settings__header__wrapper'>
						<Button
							type='submit'
							className='profile-settings__header__button'
							isLoading={isPending}
						>
							Сохранить изменения
						</Button>
					</div>
				</div>
				<section className='profile-settings__widgets'>
					<ProfileSecondaryInfo
						control={control}
						avatar={userProfile.avatar ?? defaultAvatar}
						setValue={setValue}
						errors={errors}
					/>
					<MainInfoSettings
						control={control}
						errors={errors}
						watch={watch}
					/>
					<ProfileSettingsContacts
						control={control}
						errors={errors}
					/>
				</section>
			</form>
			{isModalOpen && (
				<Modal
					className='profile-settings__modal'
					onClose={handleClose}
				>
					<div className='profile-settings__modal__content'>
						<h2 className='uppercase bold'>Изменение профиля</h2>
						<p>Данные успешно обновлены</p>
						<Button
							bold
							theme='green'
							onClick={handleClose}
						>
							Понятно
						</Button>
					</div>
				</Modal>
			)}
		</div>
	)
}
