import { useMutation } from '@tanstack/react-query'
import { FC, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { Bounce, toast } from 'react-toastify'

import { PasswordRecovery } from 'widgets/passwordRecovery'

import { PhoneInput } from 'features/phoneInput'
import { TelegramAuth } from 'features/telegramAuth'

import { login, phoneAuth, register, requestCodeForSignUp } from 'shared/api/account'
import { IAuthFields, IAuthFieldsPhone, IAuthResponse } from 'shared/api/account/types'
import { createSession } from 'shared/lib/auth/sessions'
import { handleErrorStatus } from 'shared/lib/handleError'
import { toastError } from 'shared/lib/toastError'
import { useGetSession } from 'shared/lib/useGetSession'
import { useSessionStore } from 'shared/model'
import { TPopupAccountMode } from 'shared/types'
import { Button } from 'shared/ui/button'
import { Field } from 'shared/ui/field'
import { Segmented } from 'shared/ui/segmented'

import styles from './signIn.module.scss'

interface ISignIn {
	readonly handleChangeMode: (mode: TPopupAccountMode) => void
}

export const SignIn: FC<ISignIn> = ({ handleChangeMode }) => {
	const segmentedFields = ['phone', 'username'] as const
	type TSegmentedAuth = (typeof segmentedFields)[number]
	const [authMode, setAuthMode] = useState<TSegmentedAuth>('phone')
	const [isLinkPhoneToAccountMode, setIsLinkPhoneToAccountMode] = useState(false)
	const setIsPopupAccountOpen = useSessionStore((state) => state.setIsPopupAccountOpen)

	/*** Handlers ***/
	const { mutate: getSession } = useGetSession()
	const { mutate: phoneAuthMutate } = useMutation({
		mutationFn: (data: IAuthFieldsPhone) => phoneAuth(data),
		onSuccess: handleSuccessAuth,
		onError: handleErrorAuth,
	})

	const { mutate: authMutate } = useMutation({
		mutationKey: ['auth'],
		mutationFn: (data: IAuthFields) => login(data),
		onSuccess: handleSuccessAuth,
		onError: handleErrorAuth,
	})

	const {
		handleSubmit,
		formState: { errors },
		reset,
		watch,
		control,
		setFocus,
	} = useForm<IAuthFields>({ defaultValues: { phone: '', password: '', username: '' } })

	/** Effects */
	useEffect(() => {
		setFocus('phone')
	}, [])
	/** Handlers */
	const onSubmit = async ({ phone, password, username }: IAuthFields) => {
		if (authMode === 'phone' && phone) {
			phoneAuthMutate({
				phone,
				password,
			})
			return
		}
		authMutate({ username, password })
	}
	function handleSuccessAuth(tokens: IAuthResponse & { has_account_phone?: boolean }) {
		if (tokens.has_account_phone !== undefined && !tokens.has_account_phone) {
			toast.warning('Необходимо привязать телефон для продолжения', { autoClose: 7000 })
			setIsLinkPhoneToAccountMode(true)
			createSession(tokens)
			return
		}
		createSession(tokens)
		getSession()
		setIsPopupAccountOpen(false)
	}
	function handleErrorAuth(error: Error) {
		if (handleErrorStatus(error) === 401) {
			toastError({ message: 'Неверный логин или пароль, попробуйте снова' })
		} else {
			toastError({ error })
		}
	}

	const MessageToUsernameRecovery = () => (
		<div className={styles['error-recovery-username']}>
			<h4>Обратитесь к администратору</h4>
			<span>Сброс пароля по Логину более недоступен, пожалуйста, обратитесь к администратору</span>
			<Link
				className={styles['write-button']}
				target='_blank'
				to='https://t.me/volleybox_official'
			>
				<Button>Написать</Button>
			</Link>
		</div>
	)
	function handleClickForgotPassword() {
		if (authMode === 'username') {
			toast.warning(MessageToUsernameRecovery, {
				toastId: 'recovery-username',
				transition: Bounce,
				position: 'top-right',
				autoClose: 5000,
				pauseOnHover: true,
				pauseOnFocusLoss: true,
				className: styles['toast-recovery-username'],
			})
			return
		}
		handleChangeMode('password-recovery')
	}
	function handleChangeAuthMode(segmentIndex: number) {
		reset()
		setAuthMode(segmentedFields[segmentIndex])
	}
	return (
		<>
			{isLinkPhoneToAccountMode ? (
				<PasswordRecovery
					handleChangeMode={(mode: TPopupAccountMode) => {
						if (mode === 'login') {
							setIsLinkPhoneToAccountMode(false)
							return
						}
						handleChangeMode(mode)
					}}
					isUpdatePhoneNumberMode
				/>
			) : (
				<div className={styles['sign-in']}>
					<form
						className={styles.form}
						onSubmit={handleSubmit(onSubmit)}
						autoComplete='on'
					>
						<Segmented
							activeSegment={segmentedFields.indexOf(authMode)}
							handleChangeSegment={handleChangeAuthMode}
							segments={['Телефон', 'Логин']}
						>
							{authMode === 'phone' ? (
								<PhoneInput
									control={control}
									value={watch('phone') ?? ''}
									errorMessage={errors.phone?.message}
								/>
							) : (
								<Field
									control={control}
									name='username'
									label='Логин'
									error={errors.username?.message}
									required
									placeholder='Логин'
								/>
							)}
						</Segmented>
						<Field
							control={control}
							name='password'
							type='password'
							label='Пароль'
							error={errors.password?.message}
							required
							placeholder='Пароль'
						/>
						<Button
							className={styles['forgot-password']}
							link
							theme='link'
							onClick={handleClickForgotPassword}
						>
							Забыли пароль?
						</Button>
						<Button
							type='submit'
							centered
							bold
						>
							Войти
						</Button>
						<div className={styles.links}>
							<Button
								centered
								theme='transparent'
								type='button'
								onClick={() => handleChangeMode('register')}
							>
								Создать аккаунт
							</Button>
						</div>
					</form>
					<TelegramAuth />
				</div>
			)}
		</>
	)
}
