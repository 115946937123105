import { useMutation } from '@tanstack/react-query'
import clsx from 'clsx'
import Cookies from 'js-cookie'
import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { CodeForm } from 'widgets/codeForm'

import { PhoneInput } from 'features/phoneInput'

import { IRegisterFields, register, requestCodeForSignUp, signUp } from 'shared/api/account'
import { STORE_SENDED_CODE_TIME } from 'shared/constants'
import { InfoIcon } from 'shared/icons'
import { createSession } from 'shared/lib/auth/sessions'
import { handleErrorFieldMessage } from 'shared/lib/handleError'
import { useGetSession } from 'shared/lib/useGetSession'
import { setBlockToRepeatRequestPhoneConfirm } from 'shared/lib/utils'
import { useSessionStore } from 'shared/model'
import { TPopupAccountMode } from 'shared/types'
import { BackButton } from 'shared/ui/backButton'
import { Button } from 'shared/ui/button'
import { Checkbox } from 'shared/ui/checkbox'
import { Field } from 'shared/ui/field'

import styles from './register.module.scss'

interface IRegister {
	readonly onChangeMode: (mode: TPopupAccountMode) => void
}

export const Register: FC<IRegister> = ({ onChangeMode }) => {
	const [isConfirmMode, setIsConfirmMode] = useState(false)
	const {
		handleSubmit,
		formState: { errors },
		control,
		setError,
		watch,
	} = useForm<IRegisterFields & { policy: boolean }>({ mode: 'onTouched' })

	const setIsPopupAccountOpen = useSessionStore((state) => state.setIsPopupAccountOpen)
	const sendCodeTime = Cookies.get(STORE_SENDED_CODE_TIME)
	/*** Handlers ***/
	const { mutate: getSession } = useGetSession()
	const { mutate: requestCodeForSignUpMutate, isPending: isPendingRequestCode } = useMutation({
		mutationFn: (data: { phone: string }) => requestCodeForSignUp(data),
		onSuccess: () => {
			setBlockToRepeatRequestPhoneConfirm()
			setIsConfirmMode(true)
		},
	})
	const { mutate: signUpMutate, isPending: isPendingSingUp } = useMutation({
		mutationFn: (data: IRegisterFields) => signUp(data),
		onSuccess: (response) => {
			requestCodeForSignUpMutate(response)
		},
		onError: (error) => {
			const phoneError = handleErrorFieldMessage(error, 'phone')
			const passwordError = handleErrorFieldMessage(error, 'password')
			if (phoneError) {
				if (!passwordError && phoneError === 'Need check.') {
					requestCodeForSignUpMutate({ phone: watch('phone') })
					return
				}
				setError('phone', { message: phoneError })
			}

			if (passwordError) {
				setError('password', { message: passwordError })
			}
		},
	})
	const { mutate: registerMutate, isPending: isPendingRegister } = useMutation({
		mutationFn: (data: { phone: string; code: number }) => register(data),
		onSuccess: (tokens) => {
			createSession(tokens)
			getSession()
			setIsPopupAccountOpen(false)
		},
	})
	const onSubmit = (values: IRegisterFields) => {
		if (sendCodeTime) {
			setIsConfirmMode(true)
			return
		}
		signUpMutate(values)
	}
	function handleBack() {
		if (isConfirmMode) {
			setIsConfirmMode(false)
			return
		}
		onChangeMode('login')
	}
	const renderFieldError = (message?: string) => (
		<p className={clsx(styles.error, 'red-highlight')}>
			<InfoIcon />
			{message || 'Поле обязательно для заполнения'}
		</p>
	)

	return (
		<section className={styles['sign-up']}>
			{isConfirmMode ? (
				<CodeForm
					onSubmit={(code: number) => {
						registerMutate({ code, phone: watch('phone') })
					}}
					isLoading={isPendingRegister}
					phone={watch('phone')}
				/>
			) : (
				<>
					<form
						className={styles.form}
						onSubmit={handleSubmit(onSubmit)}
						autoComplete='on'
					>
						<PhoneInput
							control={control}
							value={watch('phone')}
							errorMessage={errors.phone?.message}
						/>
						<Field
							control={control}
							name='password'
							type='password'
							label='Пароль'
							error={errors.password?.message}
							autoComplete='new-password'
							controlPattern={{
								value: /^.{8,}$/,
								message: '8 и более символов',
							}}
							required={false}
							placeholder='Пароль'
						/>
						<Field
							control={control}
							name='password_confirmation'
							type='password'
							label='Подтвердите пароль'
							autoComplete='off'
							validate={(value) => value === watch('password') || 'Пароли не совпадают'}
							error={errors.password_confirmation?.message}
							required={false}
							placeholder='Подтвердите пароль'
						/>
						<Controller
							name='policy'
							control={control}
							defaultValue={false}
							rules={{
								validate: (value) => value || 'Ознакомьтесь и примите политику конфиденциальности',
							}}
							render={({ field }) => (
								<div className={styles['form-control']}>
									<Checkbox
										{...field}
										value={field.value?.toString()}
										checked={field.value}
										label={
											<span>
												Я даю согласие на обработку{' '}
												<Link
													className={styles.policy}
													to='/page/policy'
												>
													персональных данных
												</Link>
											</span>
										}
										aria-invalid={errors.policy ? 'true' : 'false'}
									/>
									{errors.policy && renderFieldError(errors.policy.message)}
								</div>
							)}
						/>
						<Button
							isLoading={isPendingSingUp || isPendingRequestCode}
							type='submit'
						>
							Зарегистрироваться
						</Button>
					</form>
				</>
			)}
			<BackButton handleBack={handleBack} />
		</section>
	)
}
