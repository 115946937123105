import { FC } from 'react'

import styles from './segmented.module.scss'

interface ISegmented {
	readonly handleChangeSegment: (segment: number) => void
	readonly segments: readonly string[]
	readonly activeSegment: number
	readonly children: React.ReactNode
}

export const Segmented: FC<ISegmented> = ({
	activeSegment,
	children,
	handleChangeSegment,
	segments,
}) => {
	return (
		<div className={styles.wrapper}>
			<div className={styles.segments}>
				<div
					className={styles.slider}
					style={{ transform: `translateX(${activeSegment * 100}%)` }}
				/>
				{segments.map((segment, index) => (
					<button
						key={index}
						onClick={() => handleChangeSegment(index)}
						className={styles.item}
						type='button'
					>
						{segment}
					</button>
				))}
			</div>
			{children}
		</div>
	)
}
