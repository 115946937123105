import { useMutation } from '@tanstack/react-query'
import { FC, useEffect, useRef } from 'react'

import { ITelegramUser, telegramAuth } from 'shared/api/account'
import { createSession } from 'shared/lib/auth/sessions'
import { useGetSession } from 'shared/lib/useGetSession'
import { useSessionStore } from 'shared/model'

import './telegramAuth.scss'

interface ITelegramAuth {}

export const TelegramAuth: FC<ITelegramAuth> = () => {
	const setIsPopupAccountOpen = useSessionStore((state) => state.setIsPopupAccountOpen)
	const { mutate: getSession } = useGetSession()
	const { mutate: telegramAuthMutate } = useMutation({
		mutationKey: ['telegramAuth'],
		mutationFn: (user: ITelegramUser) => telegramAuth(user),
		onSuccess: (tokens) => {
			createSession(tokens)
			getSession()
			setIsPopupAccountOpen(false)
		},
	})
	const containerRef = useRef<HTMLDivElement | null>(null)
	useEffect(() => {
		function handleTelegramAuth(user: ITelegramUser) {
			telegramAuthMutate(user)
		}

		window.onTelegramAuth = handleTelegramAuth
		const script = document.createElement('script')
		script.src = 'https://telegram.org/js/telegram-widget.js?22'
		script.async = true
		script.setAttribute('data-telegram-login', 'kovdmit_bot')
		script.setAttribute('data-size', 'large')
		script.setAttribute('data-onauth', 'onTelegramAuth(user)')
		script.setAttribute('data-request-access', 'write')
		script.setAttribute('borderRadius', '16')

		containerRef.current?.appendChild(script)
	}, [])
	return <div ref={containerRef} />
}
