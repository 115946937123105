import clsx from 'clsx'
import { FC, useEffect, useRef } from 'react'
import { IconBaseProps } from 'react-icons'
import { FaArrowLeft } from 'react-icons/fa6'

import { CloseIcon } from 'shared/icons'

import { BackButton } from '../backButton'
import { Logo } from '../logo'

import styles from './modal.module.scss'

interface IModal {
	readonly children: React.ReactNode
	readonly onClose: () => void
	readonly title?: string
	readonly isHighPriority?: boolean
	readonly closeButton?: boolean
	readonly className?: string
	readonly showLogo?: boolean
	readonly background?: 'main' | 'gray'
}

export const Modal: FC<IModal> = ({
	children,
	onClose,

	closeButton = false,
	className,
	title,
	background = 'main',
	isHighPriority,
	showLogo = true,
}) => {
	const modalRef = useRef<HTMLDivElement>(null)
	useEffect(() => {
		document.body.style.overflow = 'hidden'
		modalRef.current?.focus()
		return () => {
			document.body.style.overflow = ''
		}
	}, [])
	const handleClose = () => {
		modalRef.current?.classList.add(styles['modal-out'])
		setTimeout(() => {
			onClose()
		}, 500)
	}
	return (
		<div
			id={'modal-dialog'}
			ref={modalRef}
			onMouseDown={(e) => {
				if (e.target === modalRef.current) {
					handleClose()
				}
			}}
			onMouseUp={(e) => e.stopPropagation()}
			className={clsx(styles['modal-overlay'], isHighPriority && styles['high-priority'])}
			role='dialog'
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className={clsx(styles['modal-wrapper'], className)}
				data-hide-logo={!showLogo}
				data-background={background}
			>
				{showLogo && (
					<div className={styles.logo}>
						<Logo />
					</div>
				)}
				{title && <h5 className={styles.title}>{title}</h5>}
				<div
					onClick={(e) => e.stopPropagation()}
					className={styles['modal-content']}
				>
					{children}
				</div>

				{closeButton && (
					<button
						onClick={handleClose}
						aria-label='Закрыть окно'
						className={styles.close}
					>
						<CloseIcon />
					</button>
				)}
			</div>
		</div>
	)
}
