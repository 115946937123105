import { FC } from 'react'
import { Link } from 'react-router-dom'

import { SOCIAL } from 'shared/constants'
import { useResize } from 'shared/lib/useResize'
import { Logo } from 'shared/ui/logo'

import './Footer.scss'

interface IFooter {}
export const Footer: FC<IFooter> = () => {
	const { MD_SCREEN } = useResize()
	return (
		<footer className='footer'>
			<div className='footer__wrapper'>
				{!MD_SCREEN && (
					<Link
						to='/'
						tabIndex={-1}
					>
						<Logo />
					</Link>
				)}
				<div className='footer__wrapper__social'>
					{SOCIAL.map(({ name, link, Icon }) => (
						<a
							key={name}
							href={link}
							aria-label={name}
							target='_blank'
							rel='noopener nofollow noreferrer'
							tabIndex={-1}
						>
							<Icon className='footer__wrapper__social__icon' />
						</a>
					))}
				</div>
				<Link
					className='footer__wrapper__policy'
					to='/page/policy'
					tabIndex={-1}
				>
					Политика конфиденциальности
				</Link>
			</div>
		</footer>
	)
}
