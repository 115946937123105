import { useMutation } from '@tanstack/react-query'
import Cookies from 'js-cookie'
import { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IconBaseProps } from 'react-icons'
import { MdKeyboardArrowRight } from 'react-icons/md'

import { CodeForm } from 'widgets/codeForm'

import { PhoneInput } from 'features/phoneInput'

import {
	requestCodeForSignUp,
	requestCodeForUpdatePhoneNumber,
	resetPassword,
	setNewPasswordAfterReset,
	updatePhoneNumber,
} from 'shared/api/account'
import { IRecoveryPasswordFields } from 'shared/api/account/types'
import { STORE_SENDED_CODE_TIME } from 'shared/constants'
import { createSession } from 'shared/lib/auth/sessions'
import { handleErrorFieldMessage } from 'shared/lib/handleError'
import { useGetSession } from 'shared/lib/useGetSession'
import { setBlockToRepeatRequestPhoneConfirm } from 'shared/lib/utils'
import { useSessionStore } from 'shared/model'
import { TPopupAccountMode } from 'shared/types'
import { BackButton } from 'shared/ui/backButton'
import { Button } from 'shared/ui/button'
import { Field } from 'shared/ui/field'

import styles from './passwordRecovery.module.scss'

const Arrow = MdKeyboardArrowRight as React.ComponentType<IconBaseProps>
interface IPasswordRecovery {
	readonly handleChangeMode: (mode: TPopupAccountMode) => void
	readonly isUpdatePhoneNumberMode?: boolean
}

export const PasswordRecovery: FC<IPasswordRecovery> = ({
	handleChangeMode,
	isUpdatePhoneNumberMode,
}) => {
	/** Consts */

	/** Hooks */
	const [isConfirmMode, setIsConfirmMode] = useState(false)
	const setIsPopupAccountOpen = useSessionStore((state) => state.setIsPopupAccountOpen)
	const sendedCodeTime = Cookies.get(STORE_SENDED_CODE_TIME)

	const [isInputNewPasswordMode, setIsInputNewPasswordMode] = useState(false)
	const [code, setCode] = useState<number>(0)

	const {
		control,
		watch,
		formState: { errors },
		setError,
		handleSubmit,
	} = useForm<{ phone: string }>()
	type TSetNewPasswordFields = { password: string; password_confirmation: string }
	const {
		control: passwordControl,
		handleSubmit: passwordsSubmit,
		formState: { errors: passwordsErrors },
		setError: setPasswordsError,
		watch: watchPasswords,
	} = useForm<TSetNewPasswordFields>({ mode: 'onTouched' })
	const { mutate: resetPasswordMutate, isPending } = useMutation({
		mutationFn: (phone: { phone: string }) =>
			isUpdatePhoneNumberMode ? requestCodeForUpdatePhoneNumber(phone) : resetPassword(phone),
		onSuccess: () => {
			setIsConfirmMode(true)
			setBlockToRepeatRequestPhoneConfirm()
		},
		onError: (error) => {
			const message = handleErrorFieldMessage(error, 'phone')
			if (message) {
				setError('phone', { message })
			}
		},
	})
	const { mutate: getSession } = useGetSession()
	const { mutate: updatePhoneNumberMutate } = useMutation({
		mutationFn: (data: { phone: string; code: number }) => updatePhoneNumber(data),
		onSuccess: (tokens) => {
			createSession(tokens)
			getSession()
			setIsPopupAccountOpen(false)
		},
	})

	const { mutate: recoveryPasswordMutate } = useMutation({
		mutationFn: (data: IRecoveryPasswordFields) => setNewPasswordAfterReset(data),
		onSuccess: (tokens) => {
			createSession(tokens)
			getSession()
			setIsPopupAccountOpen(false)
		},
		onError: (error) => {
			const message = handleErrorFieldMessage(error, 'password')
			if (message) setPasswordsError('password', { message })
		},
	})
	/** Effects */

	/** Handlers */
	function onSubmit(values: { phone: string }) {
		if (sendedCodeTime) {
			setIsConfirmMode(true)
			return
		}
		resetPasswordMutate(values)
	}
	function handleBack() {
		if (isInputNewPasswordMode) {
			setIsInputNewPasswordMode(false)
			return
		}
		if (isConfirmMode) {
			setIsConfirmMode(false)
			return
		}
		handleChangeMode('login')
	}

	const renderPasswordsInputs = () => {
		function onSubmit({ password, password_confirmation }: TSetNewPasswordFields) {
			recoveryPasswordMutate({ code, phone: watch('phone'), password, password_confirmation })
		}
		return (
			<form
				className={styles.form}
				onSubmit={passwordsSubmit(onSubmit)}
			>
				<Field
					control={passwordControl}
					name='password'
					type='password'
					label='Пароль'
					error={passwordsErrors.password?.message}
					autoComplete='new-password'
					controlPattern={{
						value: /^.{8,}$/,
						message: '8 и более символов',
					}}
					required={false}
					placeholder='Пароль'
				/>
				<Field
					control={passwordControl}
					name='password_confirmation'
					type='password'
					label='Подтвердите пароль'
					autoComplete='off'
					validate={(value) => value === watchPasswords('password') || 'Пароли не совпадают'}
					error={passwordsErrors.password_confirmation?.message}
					required={false}
					placeholder='Подтвердите пароль'
				/>
				<Button
					centered
					type='submit'
				>
					Изменить
				</Button>
			</form>
		)
	}
	return (
		<>
			{isConfirmMode ? (
				<>
					{isInputNewPasswordMode ? (
						renderPasswordsInputs()
					) : (
						<CodeForm
							phone={watch('phone')}
							onSubmit={(code: number) => {
								if (isUpdatePhoneNumberMode) {
									updatePhoneNumberMutate({ code, phone: watch('phone') })
									return
								}
								setCode(code)
								setIsInputNewPasswordMode(true)
							}}
						/>
					)}
				</>
			) : (
				<>
					<form
						onSubmit={handleSubmit(onSubmit)}
						className={styles.recovery}
					>
						<PhoneInput
							control={control}
							value={watch('phone')}
							errorMessage={errors.phone?.message}
						/>
						<Button
							className={styles.button}
							type='submit'
							isLoading={isPending}
						>
							<Arrow />
						</Button>
					</form>
				</>
			)}
			<BackButton handleBack={handleBack} />
		</>
	)
}
