import { useQuery } from '@tanstack/react-query'
import { useEffect, useRef, useState } from 'react'

import { Tabs } from 'widgets/tabs/ui/tabs'

import { ProductCard } from 'entities/productCard'

import { getGroupProducts } from 'shared/api/product'
import { IGroupProduct } from 'shared/api/product/types'
import { useSessionStore } from 'shared/model'
import { LoaderProducts } from 'shared/ui/loaders'
import { Tab } from 'shared/ui/tab'

import { getAllProductsOptions } from './api/groupProducts'

import './PaymentPage.scss'

const PaymentPage = () => {
	const session = useSessionStore((state) => state.session)

	const [activeTab, setActiveTab] = useState<number>(0)
	const prevActiveTabIndex = useRef<number>(0)
	useEffect(() => {
		window.scrollTo(0, 0)
	}, [activeTab])
	const { data: products, isFetching } = useQuery(getAllProductsOptions(session?.id || 'guest'))
	const handleChangeTab = (tab: number) => {
		if (tab === activeTab) return
		prevActiveTabIndex.current = activeTab
		setActiveTab(tab)
	}
	const filterProducts = (products: IGroupProduct[]) => {
		return products.filter((product) => product.is_published)
	}
	const getActiveProduct = (products: IGroupProduct[]) => {
		if (products && products.length > 0) {
			return products?.[activeTab].products
		}
		return []
	}

	return (
		<div className='products'>
			{isFetching && <LoaderProducts />}
			{products && !isFetching && (
				<>
					<Tabs
						activeTab={activeTab}
						handleChangeTab={handleChangeTab}
						tabs={filterProducts(products).map(({ title }) => title)}
						prevActiveTab={prevActiveTabIndex.current}
						sticky
					>
						<section className='products__content'>
							<ul className='products__list'>
								{getActiveProduct(products).map((product) => (
									<li key={product.id}>
										<ProductCard product={product} />
									</li>
								))}
							</ul>
						</section>
					</Tabs>
				</>
			)}
		</div>
	)
}

export default PaymentPage
