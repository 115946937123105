export function handleErrorFieldMessage(error: any, fieldName: string): string | null {
	if ('response' in error && 'data' in error.response) {
		const { data } = error.response
		const message = data[fieldName]
		if (message) {
			if (Array.isArray(message)) {
				if (message?.[0]) {
					return message?.[0]
				}
				return null
			}
			return message
		}
	}
	return null
}
