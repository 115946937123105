import { create } from 'zustand'
import { persist } from 'zustand/middleware'

import { IUserData } from 'shared/api/account'

type TSession = Pick<IUserData, 'id'> & Pick<IUserData, 'balance'> & Pick<IUserData, 'near_klass'>
interface ISessionStore {
	session: TSession | null
	setSession: (session: TSession | null) => void
	isPopupAccountOpen: boolean
	setIsPopupAccountOpen: (isPopupAccountOpen: boolean) => void
	isPopupRegisterOpen: boolean
	setIsPopupRegisterOpen: (isPopupRegisterOpen: boolean) => void
}

export const useSessionStore = create<ISessionStore>()(
	persist(
		(set, get) => ({
			session: null,
			setSession: (session) => set({ session }),
			isPopupAccountOpen: false,
			setIsPopupAccountOpen: (isPopupAccountOpen) => set({ isPopupAccountOpen }),
			isPopupRegisterOpen: false,
			setIsPopupRegisterOpen: (isPopupRegisterOpen) => set({ isPopupRegisterOpen }),
		}),
		{
			name: 'volleybox-session',
			partialize: (state) => ({
				session: state.session,
			}),
		}
	)
)
