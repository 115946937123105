import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { STORE_ACCESS_TOKEN_NAME, STORE_REFRESH_TOKEN_NAME } from 'shared/constants'
import { clearSession, createSession } from 'shared/lib/auth/sessions'
import { handleErrorMessage, handleErrorStatus } from 'shared/lib/handleError'
import { toastError } from 'shared/lib/toastError'

import { updateToken } from './account'

export const apiHostName = process.env.REACT_APP_HOST_NAME || ''

class ApiInstance {
	private axios: AxiosInstance

	constructor(version = 'v1') {
		this.axios = axios.create({
			baseURL: `${apiHostName}/api/${version}/`,
			timeout: 120000,
			headers: {
				'Content-Type': 'application/json',
			},
		})
		this.axios.interceptors.response.use(
			(response) => response,
			async (error) => {
				const status = handleErrorStatus(error)

				if (status !== 401 && !error.config.url.includes('token/refresh/')) {
					toastError({ error })
				}
				throw error
			}
		)
	}
	async get<T = any, D = any>(endpoint: string, config?: AxiosRequestConfig<D>): Promise<T> {
		const response = await this.axios.get(endpoint, config)
		return response.data
	}

	async post<T = any, D = any>(
		endpoint: string,
		data: D,
		config?: AxiosRequestConfig<D>
	): Promise<T> {
		const response = await this.axios.post(endpoint, data, config)
		return response.data
	}
}

class TokenApiInstance {
	private axios: AxiosInstance

	constructor(version = 'v1') {
		this.axios = axios.create({
			baseURL: `${apiHostName}/api/${version}/`,
			timeout: 120000,
			headers: {
				'Content-Type': 'application/json',
			},
		})
		this.axios.interceptors.request.use((config) => {
			const accessToken = localStorage.getItem(STORE_ACCESS_TOKEN_NAME)

			if (config.headers && accessToken) {
				config.headers.Authorization = `Bearer ${accessToken}`
			}
			return config
		})

		this.axios.interceptors.response.use(
			(response) => response,
			async (error) => {
				const originalRequest = error.config
				const message = handleErrorMessage(error)
				const status = handleErrorStatus(error)
				console.log(originalRequest)
				console.log(error)
				if (status !== 401) {
					toastError({ error })
				}
				if (error.response.status === 401 && error.config && !error.config._isRetry) {
					originalRequest._isRetry = true
					try {
						const refresh = localStorage.getItem(STORE_REFRESH_TOKEN_NAME)
						if (refresh) {
							const response = await updateToken()
							clearSession()
							createSession(response)
						}
						return this.axios.request(originalRequest)
					} catch (error) {
						const msg = handleErrorMessage(error)
						if (msg === 'Token is invalid or expired') clearSession()
					}
				}
				throw error
			}
		)
	}

	async get<T = any, D = any>(endpoint: string, config?: AxiosRequestConfig<D>): Promise<T> {
		const response = await this.axios.get(endpoint, config)
		return response.data
	}
	async post<T = any, D = any>(
		endpoint: string,
		body: D,
		config?: AxiosRequestConfig<D>
	): Promise<T> {
		const response = await this.axios.post(endpoint, body, config)
		return response.data
	}
	async patch<T = any, D = any>(
		endpoint: string,
		body: D,
		config?: AxiosRequestConfig<D>
	): Promise<T> {
		const response = await this.axios.patch(endpoint, body, config)
		return response.data
	}
	async delete<T = any, D = any>(endpoint: string, config?: AxiosRequestConfig<D>): Promise<T> {
		const response = await this.axios.delete(endpoint, config)
		return response.data
	}
}

export const tokenApi = new TokenApiInstance()
export const api = new ApiInstance()

export const tokenApiV2 = new TokenApiInstance('v2')
export const apiV2 = new ApiInstance('v2')
